// apiService.ts

import  { AxiosRequestConfig } from 'axios';
import axios from '../helpers/axiosInterceptor'
import { Endpoints } from '../constants/Endpoints';
import { OptionalServicesRequestBody } from '../models/app';

export const GetAdditionalServices = async (itemId:string, quantity:string, CustomerId:string) => {
  
  try {
    
    // const itemId = '02597538-d59e-ee11-be38-00224832a50d';
    // const quantity = 1;
    
    const newQuantity = parseInt(quantity);
    // console.log(itemId);
    const config: AxiosRequestConfig = {
      headers: {
        // withCredentials: true,
        'CustomerId': CustomerId??`6ebdaed5-39d5-ee11-904c-6045bdece3b0`,
        'Content-Type': 'application/json',
      },
      params: {
        itemId: itemId,
        quantity: newQuantity
      }
    };
    const response:any = await axios.get(Endpoints.GetAdditionalServices, config);
        // Check for unauthorized error
        if (response.data.Success === 0 && response.data.ErrorCode === 1) {
          // Handle unauthorized error, for example, redirect to login page
          console.error('Un expected error.');
          // You can redirect the user to the login page or show a login modal
          // For example, uncomment the line below to redirect to the login page
          // window.location.href = '/login';
          throw new Error('Un expected error');
        }
    
      return response.data;
    
    
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
