import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLogin } from "../../hooks/useLogin";
// import ReCAPTCHA from "react-google-recaptcha";
import AlertModal from "../../components/UI/alert/Alert";
import Loader from "../../components/UI/loader/Loader";
import Paper from "@mui/material/Paper";
import "./LoginPage.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Divider, IconButton, InputAdornment } from "@mui/material";
import { useLocation } from "react-router-dom";
import { usePlaceOrder } from "../../hooks/usePlaceOrder";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { RootState } from "../../store/ducks";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';


// function Copyright(props: any) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="/">
//         TireZone
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

export default function LoginPage() {

  const location = useLocation();

const errorMessage = () => {
    
};

  const { login, res, loading, error, GoogleLoginService } = useLogin();
  const selectedLocation = useSelector(
    (state: RootState) => state?.store?.selectedLocation
  );
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [missing, setMissing] = React.useState(false);
  const [missingMail, setMissingMail] = React.useState(false);
  const [password, setPassword] = React.useState("");
  // const recaptchaRef = React.useRef(null);
  // const [recaptchaValue, setRecaptchaValue] = React.useState<any>(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const { placeOrder } = usePlaceOrder();

  const searchParams = new URLSearchParams(location.search);
  const isSignup = searchParams.get("isSignup") === "true";
  const isCheckout = searchParams.get("isCheckout") === "true";
  const note = searchParams.get("note")?? "";

  // const handleRecaptcha = (token: any) => {
  //   setRecaptchaValue(token);
  // };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.currentTarget.value);
    if (event.currentTarget.validity.valid) {
      setEmailError(false);
      setMissingMail(false);
    } else {
      setEmailError(true);
    }
  };
  

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!password) setMissing(true);
    if (!email) setMissingMail(true);
    else{ // if (recaptchaValue) 
      setMissing(false);
      setMissingMail(false);
      const userData = {
        eMail: data.get("email"),
        password: data.get("password"),
      };

      const newUser = await login(userData, isCheckout);
      // console.log(isCheckout, isSignup, newUser);
      if (isCheckout && isSignup) {
        await placeOrder(newUser?.id ?? "", note);
      }
      // setResetKey(Date.now());
    }
  };
  const handleSubmitGoogle = async (response:any) => {
      // console.log(response);
      if(response){

        const userData = {
          locationCode: selectedLocation.code,
          token: response.credential,
        };
        
        const newUser = await GoogleLoginService(userData, isCheckout);
        // console.log(isCheckout, isSignup, newUser);
      if (isCheckout && isSignup) {
        await placeOrder(newUser?.id ?? "", note);
      }
      
    
      }
  };


  return (
    <Grid container sx={{ height: "100%" ,backgroundColor:'#fff'}}>
      <Helmet>
      {/* <script src="https://accounts.google.com/gsi/client" async></script> */}
      <title>Login Page</title>
          <meta name="description" content="Login to TireZone" />
          <meta name="keywords" content="login, TireZone, authentication" />

      </Helmet>
      {loading && <Loader></Loader>}
      <CssBaseline />
      <Grid className="LoginSignUpScreen"
      style={{
        backgroundImage: selectedLocation?.signInImage
          ? `url(${selectedLocation?.signInImage})`
          : `url(${require("../../assets/images/banner/banner-5.png")})`,
      }}
      item xs={false} sm={4} md={7}></Grid>

      <Grid
        item
        // xs={12}
        sm={6}
        md={5}
        component={Paper}
        elevation={0}
        square
        sx={{ backgroundColor: "#fff" }}
      >
        <Box
          sx={{
            my: 4,
            mx: 6,
            mt: 3,
            ml: 3,
            mr: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4" color={"#E7131A"}>
            {loading ? "Logging in.." : "Login"}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              autoFocus
              required
              fullWidth
              value={email}
              onChange={handleEmailChange}
              error={emailError || missingMail}
              helperText={
                emailError
                  ? "Enter a valid email"
                  : missingMail
                  ? "Please Enter Mail"
                  : ""
              }
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              type="email"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#2B313E",
                  },
                  "&:hover fieldset": {
                    borderColor: "#2B313E",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2B313E",
                },
              }}
              InputProps={{ style: { borderRadius: "10px" } }}
              InputLabelProps={{
                style: {
                  color: "#2B313E", // Label color
                },
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);
                if (password) setMissing(false);
              }}
              error={missing}
              helperText={missing ? "Please Enter Password" : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { borderRadius: "10px" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#2B313E",
                  },
                  "&:hover fieldset": {
                    borderColor: "#2B313E",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2B313E",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#2B313E", // Label color
                },
              }}
            />

            {/* <ReCAPTCHA
              ref={recaptchaRef}
              key={resetKey}
              sitekey={
                process.env.REACT_APP_RECAPTCHA
                  ? process.env.REACT_APP_RECAPTCHA
                  : "6LduOKspAAAAAHBemUO1QC7yPuPgShSG0_rsdWA9"
              }
              
              onChange={(token: any) => handleRecaptcha(token)}
              style={{
                // marginBottom: "0.5rem",
                marginTop: "1rem",
                width: "100%",
              }}
            /> */}
            {error && (
              <Typography sx={{ color: "red", fontSize: "small", mt: 1 }}>
                {error}
              </Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#E7131A",
                borderRadius: "5px",
              }}
              className="theme-btn btn-style-one"
            >
              Login
              <i className="flaticon-right-arrow"></i>
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgotPass" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
        <Box sx={{display:'flex', alignItems:'center',justifyContent:'center' , flexDirection:'column',my:'2rem'}}>
          <div style={{display:'flex',flexDirection:'row', width:'100%',alignItems:'center',justifyContent:'space-between'}}>
            <Divider sx={{ width:  "45%" , borderBottom: '1px solid #ccc' }}/>
            Or
            <Divider sx={{ width:  "45%" , borderBottom: '1px solid #ccc' }}/>
            </div>
            
          {/* <Button onClick={()=>Google_Auth()} sx={{my:'2rem'}}>
            Login With Google {" "}
            <Google color="error" sx={{mx:1}}/>
          </Button> */}
           <GoogleOAuthProvider
            clientId={ 
              "894661361392-pi86vu1fr98pbo074j35q85gap68ch3j.apps.googleusercontent.com"
            }
          >
            
          <GoogleLogin onSuccess={handleSubmitGoogle} onError={errorMessage} />
          </GoogleOAuthProvider>

        </Box>
          </Box>
        </Box>
      </Grid>
      {res && <AlertModal severity="success" text={res}></AlertModal>}
    </Grid>
  );
}
