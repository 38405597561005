import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import "./AccountPage.css";
import { Edit } from "@mui/icons-material";
import PhoneIcon from "@mui/icons-material/Phone";
import AddressIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useUpdateProfile } from "../../hooks/useUpdateProfile";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// import ReCAPTCHA from "react-google-recaptcha";
import AlertModal from "../../components/UI/alert/Alert";
import tyreImage from "../../assets/images/tyre4.png";
import Loader from "../../components/UI/loader/Loader";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import LandingPage from "../LandingPage/LandingPage";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { RootState } from "../../store/ducks";

function AccountPage() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user") ?? `""`)
  );
  const [edit, setEdit] = useState(false);
  const [editedUser, setEditedUser] = useState(user);
  const [emailError, setEmailError] = useState(false);
  const { updateProfile, res, loading, error } = useUpdateProfile();
  const [resetKey, setResetKey] = React.useState(Date.now());
  const recaptchaRef = React.useRef(null);
  const [recaptchaValue, setRecaptchaValue] = React.useState<any>(null);
  const navigate = useNavigate();
  const selectedLocation = useSelector(
    (state: RootState) => state?.store?.selectedLocation
  );

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") ?? `""`);
    const isLoggedIn = user && user !== "" && user && user !== "guest";  
    if(!isLoggedIn) navigate('/')
  }, []);

  // const handleRecaptcha = (token: any) => {
  //   setRecaptchaValue(token);
  // };

  const handleSaveChanges = async () => {
    // if (recaptchaValue) {
      setEdit(false);
      await updateProfile(editedUser);
    // }
  };

  const handleEdit = () => {
    setEditedUser(user);
    setEdit(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    // <div className="containerAS">
    <Grid
      container
      component="main"
      margin={0}
      sx={{ justifyContent: "center", marginTop: "2rem" }}
    >
       <Helmet>
            <meta charSet="utf-8" />
            <title>Account Page</title>
            <meta name="description" content="This is the account page" />
            <meta name="keywords" content="account, user, profile" />
            <meta name="author" content="Account Page" />
            <meta name="account" content={JSON.stringify(user)} />
            
          </Helmet>
      <CssBaseline />
      <Grid className="LoginSignUpScreen"
      style={{
        backgroundImage: selectedLocation?.signInImage
          ? `url(${selectedLocation?.signInImage})`
          : `url(${require("../../assets/images/banner/banner-5.png")})`,
      }}
      item xs={false} sm={4} md={7} />

      <Grid
        item
        // xs={12}
        sm={6}
        md={5}
        component={Paper}
        elevation={0}
        // square
        sx={{ backgroundColor: "#F9F9F9" }}
      >
        <Box
          sx={{
            // my: 3,
            mx: 3,
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            ml: 0,
          }}
        >
          <div className="profile-header" style={{alignItems: edit? "center" : "flex-start"}}>
            <Avatar className="avatar" alt={user.name} src={tyreImage} />
            {!edit && (
              <Typography component="h1" variant="h4" color={"#E7131A"} mb={2}>
                {user.name}
              </Typography>
            )}
            {edit && (
              <div style={{ display: "flex", flexDirection: "column"}}>
                <Typography variant="body1" color={"#2B313E"}>
                  <span
                    style={{
                      fontWeight: "bold",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                  >
                    Name:
                  </span>
                </Typography>

                <TextField
                  variant="standard"
                  margin="normal"
                  defaultValue={user.name}
                  onChange={(e: any) =>
                    setEditedUser((prevUser: any) => ({
                      ...prevUser,
                      name: e.target.value,
                    }))
                  }
                  InputLabelProps={{
                    style: {
                      color: "#2B313E",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#2B313E",
                      },
                      "&:hover fieldset": {
                        borderColor: "#2B313E",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#2B313E",
                    },
                  }}
                />
              </div>
            )}
            {!edit && (
              <IconButton className="edit-button" onClick={handleEdit}>
                <Edit style={{ color: "#2B313E" }} />
              </IconButton>
            )}
            {edit && (
              <IconButton
                className="edit-button"
                onClick={() => setEdit(false)}
              >
                <CloseIcon style={{ color: "#2B313E" }} />
              </IconButton>
            )}
          </div>
          <Box component="form">
            <form className="form">
              {loading && <Loader></Loader>}
              {!loading && (
                <div className="profile-info">
                  {edit ? (
                    <>
                      {/* <Typography variant="body1" color={"#2B313E"}>
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          Name:
                        </span>

                                  <TextField
                variant="standard"
                defaultValue={user.name}
                onChange={(e: any) =>
                  setEditedUser((prevUser: any) => ({
                    ...prevUser,
                    name: e.target.value,
                  }))
                }
                InputLabelProps={{
                  style: {
                    color: "#2B313E",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#2B313E",
                    },
                    "&:hover fieldset": {
                      borderColor: "#2B313E",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2B313E",
                  },
                  
                }}
              />
              </Typography>
 */}
                      <Typography variant="body1" color={"#2B313E"}>
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          <MailOutlineIcon style={{ marginRight: "8px" }} />
                          Mail:
                        </span>
                        <TextField
                          variant="standard"
                          defaultValue={user.eMail}
                          onChange={(e: any) => {
                            if (e.target.validity.valid) {
                              setEmailError(false);
                              setEditedUser((prevUser: any) => ({
                                ...prevUser,
                                eMail: e.target.value,
                              }));
                            } else {
                              setEmailError(true);
                            }
                          }}
                          error={emailError}
                          helperText={emailError ? "Enter a valid email" : ""}
                          type="email"
                          autoComplete="email"
                          InputLabelProps={{
                            style: {
                              color: "#2B313E",
                            },
                          }}
                          sx={{
                            marginLeft: "0.2rem",
                            marginBottom: "0.2rem",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#2B313E",
                              },
                              "&:hover fieldset": {
                                borderColor: "#2B313E",
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: "#2B313E",
                            },
                          }}
                        />
                      </Typography>
                      <Typography variant="body1" color={"#2B313E"}>
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          <PhoneIcon style={{ marginRight: "8px" }} />
                          Phone:
                        </span>
                        <TextField
                          variant="standard"
                          type="number"
                          defaultValue={user.phoneNo}
                          onChange={(e: any) =>
                            setEditedUser((prevUser: any) => ({
                              ...prevUser,
                              phoneNo: e.target.value,
                            }))
                          }
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          InputLabelProps={{
                            style: {
                              color: "#2B313E",
                            },
                          }}
                          sx={{
                            marginLeft: "1rem",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#2B313E",
                              },
                              "&:hover fieldset": {
                                borderColor: "#2B313E",
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: "#2B313E",
                            },
                          }}
                        />
                      </Typography>
                      <Typography variant="body1" color={"#2B313E"}>
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          <PhoneIphoneIcon style={{ marginRight: "8px" }} />
                          Mobile:
                        </span>
                        <TextField
                          variant="standard"
                          type="number"
                          defaultValue={user.mobilePhoneNo}
                          onChange={(e: any) =>
                            setEditedUser((prevUser: any) => ({
                              ...prevUser,
                              mobilePhoneNo: e.target.value,
                            }))
                          }
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          InputLabelProps={{
                            style: {
                              color: "#2B313E",
                            },
                          }}
                          sx={{
                            marginLeft: "1rem",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#2B313E",
                              },
                              "&:hover fieldset": {
                                borderColor: "#2B313E",
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: "#2B313E",
                            },
                          }}
                        />
                      </Typography>

                      <Typography variant="body1" color={"#2B313E"}>
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          <AddressIcon style={{ marginRight: "8px" }} />
                          Address:
                        </span>
                        <TextField
                          variant="standard"
                          defaultValue={user.address}
                          onChange={(e: any) =>
                            setEditedUser((prevUser: any) => ({
                              ...prevUser,
                              address: e.target.value,
                            }))
                          }
                          InputLabelProps={{
                            style: {
                              color: "#2B313E",
                            },
                          }}
                          sx={{
                            marginLeft: "1rem",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#2B313E",
                              },
                              "&:hover fieldset": {
                                borderColor: "#2B313E",
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: "#2B313E",
                            },
                          }}
                        />
                      </Typography>
                      <Typography variant="body1" color={"#2B313E"}>
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          <AddLocationAltIcon style={{ marginRight: "8px" }} />
                          Address2:
                        </span>
                        <TextField
                          variant="standard"
                          defaultValue={user.address2}
                          onChange={(e: any) =>
                            setEditedUser((prevUser: any) => ({
                              ...prevUser,
                              address2: e.target.value,
                            }))
                          }
                          InputLabelProps={{
                            style: {
                              color: "#2B313E",
                            },
                          }}
                          sx={{
                            marginLeft: "1rem",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#2B313E",
                              },
                              "&:hover fieldset": {
                                borderColor: "#2B313E",
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: "#2B313E",
                            },
                          }}
                        />
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="body1"
                        margin={"normal"}
                        color={"#2B313E"}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          <MailOutlineIcon style={{ marginRight: "8px" }} />
                          Mail:
                        </span>
                        {user.eMail}
                      </Typography>
                      <Typography
                        variant="body1"
                        margin={"normal"}
                        color={"#2B313E"}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          <PhoneIcon style={{ marginRight: "8px" }} />
                          Phone:
                        </span>
                        {user.phoneNo}
                      </Typography>
                      <Typography
                        variant="body1"
                        margin={"normal"}
                        color={"#2B313E"}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          <PhoneIphoneIcon style={{ marginRight: "8px" }} />
                          Mobile:
                        </span>
                        {user.mobilePhoneNo}
                      </Typography>

                      <Typography
                        variant="body1"
                        margin={"normal"}
                        color={"#2B313E"}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          <AddressIcon style={{ marginRight: "8px" }} />
                          Address:
                        </span>
                        {user.address}
                      </Typography>
                      <Typography
                        variant="body1"
                        margin={"normal"}
                        color={"#2B313E"}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "10px" }}
                        >
                          <AddLocationAltIcon style={{ marginRight: "8px" }} />
                          Address 2:
                        </span>
                        {user.address2}
                      </Typography>
                      <br />
                    </>
                  )}
                </div>
              )}
              {res && <AlertModal severity="success" text={res}></AlertModal>}
              {error && edit && (
                <Typography sx={{ color: "red", fontSize: "small", mt: 1 }}>
                  {error}
                </Typography>
              )}

              <div className="edit">
                <div
                  className="select-btn"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {edit && (
                    <>
                      {/* <ReCAPTCHA
                        ref={recaptchaRef}
                        key={resetKey}
                        sitekey={
                          process.env.REACT_APP_RECAPTCHA
                            ? process.env.REACT_APP_RECAPTCHA
                            : ""
                        }
                        onChange={(token: any) => handleRecaptcha(token)}
                      /> */}
                      <a
                        className="theme-btn btn-style-one"
                        onClick={handleSaveChanges}
                      >
                        Save changes
                        <i className="flaticon-right-arrow"></i>
                      </a>{" "}
                    </>
                  )}
                </div>
              </div>
            </form>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AccountPage;
