import { useState } from 'react';
import CartItem from '../cartItem/CartItem';
import { CartItem as CartItemType } from '../../../models/app';
import {Grid} from "@mui/material";

const CartTable = ({ updateCart, cartItems, setCartItems, calculateTotal, removeFromCart, user }:any) => {

  const [hasAdditionalTax] = useState<Boolean>(cartItems.some((item: CartItemType) => item.additionalTax1 !== "0"))

  if (true) {

    return (
        <Grid container spacing={{xs: 1, md: 1}} columns={{xs: 12, sm: 12, md: 12}} sx={{backgroundColor:"lightgrey", paddingTop:"20px",paddingBottom:"5px"}}>
          {cartItems.map((product: any, index: number) => (
              <CartItem
                  updateCart={updateCart}
                  calculateTotal={calculateTotal}
                  setCartItems={setCartItems}
                  removeFromCart={removeFromCart}
                  key={`${product?.SystemId}-${index}`}
                  product={product}
                  hasAdditionalTax={hasAdditionalTax}
                  user={user}
              />
          ))}
        </Grid>
    );
  }


  return (
  <table className="cart-table">
    <thead className="cart-header">
      <tr>
        <th>&nbsp;</th>
        <th className="prod-column">Product Name</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th className="price">Price</th>
        {hasAdditionalTax && <th className="price">FET</th>}
        <th className="quantity">Quantity</th>
        <th>Subtotal</th>
        {((user !== "") && ((user === "guest") || (user.showAdditionnalServices === true))) && <th>Additional Services</th>}
      </tr>
    </thead>
    <tbody>
    {cartItems.map((product: any, index: number) => (
      <CartItem
        updateCart={updateCart}
        calculateTotal={calculateTotal}
        setCartItems={setCartItems}
        removeFromCart={removeFromCart}
        key={`${product?.SystemId}-${index}`}
        product={product}
        hasAdditionalTax={hasAdditionalTax}
        user={user}
      />
    ))}
    </tbody>
  </table>
  )
};

export default CartTable;
