import React, { useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
// import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { RootState } from "../../store/ducks";
import { Location } from "../../models/app";
import { useContactUs } from "../../hooks/useContactUs";
import Loader from "../../components/UI/loader/Loader";
import AlertModal from "../../components/UI/alert/Alert";
import { Helmet } from "react-helmet";

const ContactPage: React.FC = () => {
  const locations: Location[] = useSelector(
    (state: RootState) => state?.store?.locations
  );
  const { contactUs, res, loading, error } = useContactUs();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [message, setMessage] = useState("");

  const handleEmailChange = (event: any) => {
    setEmail(event.currentTarget.value);
    if (event.currentTarget.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (event.currentTarget.checkValidity() ) { //&& recaptchaValue
      await contactUs(name, email, message);
      setEmail("");
      setName("");
      setMessage("");
      // setRecaptchaValue(null);
    }
  };

  return (
    <body>
      <Helmet>
      <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
        <meta charSet="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        <meta name="format-detection" content="telephone=no"/>
        
        <title>Contact Us - Tire Zone</title>
        
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Contact Us - Tire Zone"/>
        <meta property="og:url" content="https://tirezoneusa.com/contact/"/>
        <meta property="og:site_name" content="Tire Zone"/>
        <meta property="article:publisher" content="https://www.facebook.com/pages/Tire-Zone/167358669946020"/>
        <meta property="article:modified_time" content="2022-12-03T07:51:08+00:00"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@tirezoneusa"/>
        <meta name="twitter:label1" content="Est. reading time"/>
        <meta name="twitter:data1" content="1 minute"/>
      
      </Helmet>
      {loading && <Loader />}
      <div className="boxed_wrapper" style={{paddingTop: "10px"}}>
        {/* <PageTitle title={"Contact"} /> */}
        <Container maxWidth="lg" style={{ marginTop: "20px" }}>
          <Grid container spacing={0} justifyContent="center">
            <Grid item xs={12} sm={9} md={4} lg={4}>
              <section
                className="contact-address"
                style={{ textAlign: "left", letterSpacing: "normal" }}
              >
                <div className="container">
                  <div
                    className="contact-info-content"
                    style={{
                      padding: "30px 45px",
                      textAlign: "left",
                      letterSpacing: "normal",
                    }}
                  >
                    <h6>Locations</h6>
                    {locations?.length > 0 &&
                      locations.map((location: Location) => (
                        <p>
                          Tire Zone {location.displayName} {` : `}
                          {location.address}
                        </p>
                      ))}
                    {/* <p>-Tire Zone Covina
                      15940 E San Bernardino Rd #A
                      Covina, CA 91722
                    </p> <br/>
                    <p>
                      -Tire Zone Glendora
                      321 W Arrow Hwy.
                      Glendora, CA 91740
                    </p> <br/>
                    <p>
                      -Tire Zone Ontario
                      940 West Holt Blvd.
                      Ontario, CA 91762
                    </p><br/> */}
                    <h6>Contact Us</h6>
                    {locations?.length > 0 &&
                      locations.map((location: Location) => (
                        <a className="help-info-number" href={`tel:${location.phoneNo}`}>
                          {location.displayName}: {location.phoneNo}
                        </a>                                            
                      ))}
                    {/* <a href="tel:+8001234567890" className="help-info-number"> 
                      COVINA: (626) 338-3500
                      GLENDORA: (626) 963-4567
                      ONTARIO: (909) 395-0005
                    </a> */}
                    <h6>Email Us</h6>
                    <a href="mailto:info@company.com">
                      {" "}
                      TireZoneUSA@hotmail.com
                    </a>
                    <p>CA, United States</p>
                  </div>
                </div>
              </section>
            </Grid>

            <Grid item xs={12} sm={6} md={8} lg={8}>
              <div
                className="contact-form"
                style={{ height: "fit-content", width: "fit-content" }}
              >
                <div className="contact-form-wrapper">
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      name="name"
                      className="contact-input common-input"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <input
                      type="email"
                      name="email"
                      className="contact-input common-input"
                      placeholder="Email Address"
                      // onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && (
                      <p style={{ color: "red", fontSize: "small" }}>
                        Enter a valid email
                      </p>
                    )}
                    <textarea
                      name="textarea"
                      className="contact-input"
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                    {error && (
                      <Typography
                        sx={{ color: "red", fontSize: "small", mb: 2 }}
                      >
                        {error}
                      </Typography>
                    )}

                    {/* <ReCAPTCHA
                      ref={recaptchaRef}
                      key={resetKey}
                      sitekey={
                        process.env.REACT_APP_RECAPTCHA
                          ? process.env.REACT_APP_RECAPTCHA
                          : ""
                      }
                      onChange={(token: any) => handleRecaptcha(token)}
                      style={{ marginBottom: "3rem" }}
                    /> */}
                    <button type="submit" className="theme-btn btn-style-one">
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
          {res && <AlertModal severity="success" text={res} />}
        </Container>
      </div>
    </body>
  );
};

export default ContactPage;
