import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSignup } from "../../hooks/useSignup";
import AlertModal from "../../components/UI/alert/Alert";
import { usePlaceOrder } from "../../hooks/usePlaceOrder";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
} from "@mui/material";
import Loader from "../../components/UI/loader/Loader";
// import { useGetLocations } from "../../hooks/useGetLocations";
import { styled } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks";
import { Location } from "../../models/app";
import {
  updateSelectedLocation,
  updateUserSystemId,
} from "../../store/ducks/app";
import { Helmet } from "react-helmet";
import { useLogin } from "../../hooks/useLogin";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
// import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const StyledFormControl = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#2B313E", // default
    },
    "&:hover fieldset": {
      borderColor: "#2B313E", // hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2B313E", // focused
    },
  },
  "& .MuiInputLabel-root": {
    color: "#2B313E", // default
    "&.Mui-focused": {
      color: "#2B313E", // focused
    },
  },
});

export interface SignUpQueryParams {
  isSignup: boolean;
  isCheckout: boolean;
  note: string;
}

export default function SignUpPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isCheckout = searchParams.get("isCheckout") === "true";
  const note = searchParams.get("note")?? "" ;
  const { placeOrder, ordersLoading } = usePlaceOrder();

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const { signup, signupRes, signupLoading, signupError } = useSignup();
  const { GoogleLoginService } = useLogin();
  const locations: Location[] = useSelector(
    (state: RootState) => state?.store?.locations
  );
  const selectedLocation = useSelector(
    (state: RootState) => state?.store?.selectedLocation
  );
  // const userSystemIdStore = useSelector((state: RootState) => state?.store?.userSystemId);

  const [showPassword, setShowPassword] = useState(false);
  // const [loc, setLoc] = React.useState(selectedLocation?.code ?? "");
  // const [resetKey, setResetKey] = React.useState(Date.now());
  // // const recaptchaRef = React.useRef(null);
  // const [recaptchaValue, setRecaptchaValue] = React.useState<any>(null);
  // // const handleRecaptcha = (token: any) => {
  // //   setRecaptchaValue(token);
  // // };

  const params: SignUpQueryParams = {
    isSignup: true,
    isCheckout: isCheckout,
    note: note,
  };

  const queryString = new URLSearchParams(params as any).toString();

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleLocationChange = (event: any) => {
    // console.log(event.target.value);
    dispatch(updateSelectedLocation(event?.target?.value));
    dispatch(updateUserSystemId(event?.target?.value?.guestCutomerId));
    // setSelectedLocation(event.target.value);
    // setLoc(event.target.value?.name);
  };
  const handleEmailChange = (event: any) => {
    setEmail(event.currentTarget.value);
    if (event.currentTarget.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (event.currentTarget.checkValidity()) { //&& recaptchaValue
      const userData = {
        name: data.get("name"),
        address: data.get("address"),
        phoneNo: data.get("phoneNo"),
        eMail: data.get("email"),
        password: data.get("password"),
        locationCode: selectedLocation?.code,
      };
      const newUser = await signup(userData, isCheckout);
      if (isCheckout) {
        await placeOrder(newUser ?? "", note);
      }
      // setResetKey(Date.now());
    }
  };
  const handleSubmitGoogle = async (response:any) => {
    // console.log(response);
    if(response){

      const userData = {
        locationCode: selectedLocation.code,
        token: response.credential,
      };
      
      const newUser = await GoogleLoginService(userData, isCheckout);
      // console.log(isCheckout, newUser);
    if (isCheckout) {
      await placeOrder(newUser?.id ?? "", note);
    }
    
  
    }
};
  if (isCheckout && ordersLoading) return <Loader />;
  return (
    <Grid
      container
      component="main"
      sx={{ height: "100%", overflowY: "auto", backgroundColor: "#fff" }}
    >
      <Helmet>
        <title>Sign Up Page</title>
        {/* <script src="https://accounts.google.com/gsi/client" async></script> */}
        <meta name="description" content="Sign Up to TireZone" />
        <meta
          name="keywords"
          content="Register, Sign Up, TireZone, authentication"
        />
      </Helmet>
      {signupLoading && <Loader></Loader>}
      <CssBaseline />
      <Grid
        className="LoginSignUpScreen"
        item
        xs={false}
        sm={4}
        md={7}
        style={{
          backgroundImage: selectedLocation?.signUpImage
            ? `url(${selectedLocation?.signUpImage})`
            : `url(${require("../../assets/images/banner/banner-5.png")})`,
        }}
      />
      <Grid
        item
        // xs={12}
        sm={6}
        md={5}
        component={Paper}
        elevation={0}
        // square
        sx={{ backgroundColor: "#FFFFFF" }}
      >
        <Box
          sx={{
            my: 4,
            mx: 6,
            mt: 5,
            ml: 3,
            mr: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4" color={"#E7131A"} mb={2}>
            {signupLoading ? "Signing up.." : "Sign Up"}
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              margin="dense"
              autoComplete="given-name"
              name="name"
              required
              fullWidth
              id="name"
              label="Name"
              autoFocus
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#2B313E",
                  },
                  "&:hover fieldset": {
                    borderColor: "#2B313E",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2B313E",
                },
              }}
              InputProps={{ style: { borderRadius: "10px" } }}
              InputLabelProps={{
                style: {
                  color: "#2B313E", // Label color
                },
              }}
            />

            <TextField
              margin="dense"
              autoFocus
              required
              fullWidth
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              helperText={emailError ? "Enter a valid email" : ""}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              type="email"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#2B313E",
                  },
                  "&:hover fieldset": {
                    borderColor: "#2B313E",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2B313E",
                },
              }}
              InputProps={{ style: { borderRadius: "10px" } }}
              InputLabelProps={{
                style: {
                  color: "#2B313E", // Label color
                },
              }}
            />
            <TextField
              margin="dense"
              name="phoneNo"
              required
              fullWidth
              id="phoneNo"
              label="Phone Number"
              autoFocus
              type="number"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#2B313E",
                  },
                  "&:hover fieldset": {
                    borderColor: "#2B313E",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2B313E",
                },
              }}
              InputProps={{ style: { borderRadius: "10px" } }}
              InputLabelProps={{
                style: {
                  color: "#2B313E",
                },
              }}
            />
            <TextField
              margin="dense"
              name="address"
              required
              fullWidth
              id="address"
              label="Address"
              autoFocus
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#2B313E",
                  },
                  "&:hover fieldset": {
                    borderColor: "#2B313E",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2B313E",
                },
              }}
              InputProps={{ style: { borderRadius: "10px" } }}
              InputLabelProps={{
                style: {
                  color: "#2B313E", // Label color
                },
              }}
            />
            <StyledFormControl fullWidth margin="dense">
              <InputLabel id="demo-multiple-name-label">Location</InputLabel>
              <Select
                id="demo-multiple-name"
                value={selectedLocation}
                onChange={handleLocationChange}
                input={<OutlinedInput label="Location" />}
                fullWidth
                sx={{ color: "#2B313E", borderRadius: "10px" }}
                required
              >
                {locations &&
                  locations.map((location: any) => (
                    <MenuItem key={location.displayName} value={location}>
                      {location.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </StyledFormControl>
            <TextField
              margin="dense"
              // size="small"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { borderRadius: "10px" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#2B313E",
                  },
                  "&:hover fieldset": {
                    borderColor: "#2B313E",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#2B313E",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#2B313E", // Label color
                },
              }}
            />
          <Box sx={{display:'flex',justifyContent:'center',width:'100%' }}>

  {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA??""}>
          <GoogleReCaptcha
            // className="google-recaptcha-custom-class"
            onVerify={setTokenFunc}
            refreshReCaptcha={refreshReCaptcha}
          />
        </GoogleReCaptchaProvider> */}

                </Box>
            {signupError && (<div style={{display:'flex',flexDirection:'column'}}>
              <Typography sx={{ color: "red", fontSize: "small", mt: 1 , mb:1 }}>
                {signupError}
              </Typography>
               
               {signupError.includes("reset your password") && <Link href="/forgotPass" variant="body2">
                 Forgot password?
               </Link>}
               </div>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#E7131A",
                borderRadius: "5px",
              }}
              className="theme-btn btn-style-one"
            >
              {signupLoading
                ? "Processing..."
                : isCheckout
                ? "Create Account and Place Order"
                : "Sign Up"}
              <i className="flaticon-right-arrow"></i>
            </Button>

            <Grid container justifyContent="flex-start">
              <Grid item>
                <Link href={`/login?${queryString}`} fontSize={"18px"} variant="body1">
                  {"Already have an account? Login"}
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{display:'flex', alignItems:'center',justifyContent:'center' , flexDirection:'column',width:'100%',my:'1rem'}}>
          <div style={{display:'flex',flexDirection:'row', width:'100%',alignItems:'center',justifyContent:'space-between'}}>
            <Divider sx={{ width:  "45%" , borderBottom: '1px solid #ccc' }}/>
            Or
            <Divider sx={{ width:  "45%" , borderBottom: '1px solid #ccc' }}/>
            </div>
            
          {/* <Button onClick={()=>Google_Auth()} sx={{my:'1rem'}}>
            Continue With Google {" "}
            <Google color="error" sx={{mx:1}}/>
          </Button> */}
            <GoogleOAuthProvider
            clientId={ 
              "894661361392-pi86vu1fr98pbo074j35q85gap68ch3j.apps.googleusercontent.com"
            }
          >
          
          <GoogleLogin onSuccess={handleSubmitGoogle} onError={()=>{}} />
          </GoogleOAuthProvider>
        </Box>
        </Box>
      </Grid>
      {signupRes && (
        <AlertModal severity="success" text={signupRes}></AlertModal>
      )}
    </Grid>
  );
}
