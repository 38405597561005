import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

export default function AlertModal({ severity, text, setError }: any) {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {setOpen(false); if(setError){setError(false)}}, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          severity={severity}
          style={{
            width: "80%",
            position: "fixed",
            bottom: 20,
            left: '10%',
            borderRadius: '10px',
            zIndex: 9000,
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                if (setError !== null) {
                  setOpen(false);
                  setError(false);
                } else {
                  setOpen(false);
                }
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {text}
        </Alert>
      </Collapse>
    </Box>
  );
}
