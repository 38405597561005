/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import logo from "../../assets/images/tirezonelogo.png"
import { useNavigate } from "react-router-dom";

import {
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  styled,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { menuItems, menuItemsAuth } from "../../constants/JsonObjects";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks";
import { Location, LoggedinUser, OptionalServices } from "../../models/app";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  updateSelectedLocation,
  updateUserSystemId,
} from "../../store/ducks/app";
import AccountCircleIcon from "@mui/icons-material/PermIdentity";

const MobileHeader = ({
  handleOpenMobileMenu,
  brands,
  locationsLoading,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const user: LoggedinUser | "" | "guest" = JSON.parse(
    localStorage.getItem("user") ?? `""`
  );
  const isLoggedIn = user !== null && user !== "" && user && user !== "guest";

  const menu = isLoggedIn ? menuItemsAuth : menuItems;

  const [open, setOpen] = React.useState(false);
  const [openLocations, setOpenLocations] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickLocations = () => {
    setOpenLocations(!openLocations);
  };

  const handleBrandSelection = (selectedBrand: string) => {
    // const params: HeaderQueryParams = {
    //   selectedBrand: selectedBrand,
    // };
    // const queryString = new URLSearchParams(params as any).toString();
    // navigate(`/models?${queryString}`);
    navigate(`/Tire-Brands/${selectedBrand.replace(" ", "-")}`);
  };

  const cart = useSelector((state: RootState) => state.store?.cart);
  const locationsStore = useSelector(
    (state: RootState) => state?.store?.locations
  );
  const selectedLocation = useSelector(
    (state: RootState) => state?.store?.selectedLocation
  );
  const userSystemIdStore = useSelector(
    (state: RootState) => state?.store?.userSystemId
  );

  const [totalPrice, setTotalPrice] = React.useState(0);

  const calculateTotal = () => {
    if (!cart) setTotalPrice(0);
    const newPrice = cart?.reduce((acc: any, curr: any) => {
      const serviceTotal = calculateServicesTotal(curr?.services ?? []);
      return acc + curr.price * curr.quantity + serviceTotal;
    }, 0);
    setTotalPrice(newPrice);
  };

  const calculateServicesTotal = (services: OptionalServices[]) => {
    return services.reduce(
      (acc: number, curr: OptionalServices) =>
        acc + (curr.Selected ? curr.ServicePriceWithTax : 0),
      0
    );
  };
  React.useEffect(() => {
    calculateTotal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const StyledFormControl = styled(FormControl)({
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      "& fieldset": {
        borderColor: "#2B313E", // default
      },
      "&:hover fieldset": {
        borderColor: "#2B313E", // hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2B313E", // focused
      },
    },
    "& .MuiInputLabel-root": {
      color: "#2B313E", // default
      "&.Mui-focused": {
        color: "#2B313E", // focused
      },
    },
  });

  const handleLocationChange = (event: any) => {
    const chosenLocation = locationsStore.find(
      (location: Location) => location.code === event.target.value
    );
    // console.log(event.target.value, chosenLocation);
    if (chosenLocation) {
      dispatch(updateSelectedLocation(chosenLocation));
      const flag = locationsStore.find(
        (location: Location) => location.guestCutomerId === userSystemIdStore
      );
      if (flag) {
        dispatch(updateUserSystemId(chosenLocation.guestCutomerId));
      }
    }
  };

  return (
    <AppBar position="sticky" color="primary">
      <Container style={{ padding: "0.1rem" }}>
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src={logo}
                style={{ width: 180 , marginLeft:'1rem'}}
                onClick={() => navigate("/")}
              />
            </div>
          </Typography>
          <Box sx={{ display: { xs: "flex" }, alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <a href="/cart">
                  <div
                    className="shopping-cart"
                    style={{ fontSize: 18, marginRight: ".7rem" }}
                  >
                    <i color="#fff" className="icon-shopping-cart"></i>
                    {/* <span className="count">{cart?.length ?? 0}</span> */}
                  </div>
                </a>

                <div className="shopping-cart-info" style={{ marginRight: 5 }}>
                  {" "}
                  <h6
                    style={{
                      fontSize: 12,
                      color: "#fff",
                      marginBottom: "0.2rem",
                    }}
                  >
                    {cart?.length ?? 0} items
                  </h6>{" "}
                  <h6 style={{ fontSize: 12, color: "#fff" }}>{`$ ${
                    totalPrice.toFixed(2) ?? 0
                  }`}</h6>{" "}
                </div>
              </div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                //   onClick={handleOpenMobileMenu}
                onClick={handleOpenNavMenu}
                style={{ minWidth: "10px", color: "#fff" }}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block" },
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  minWidth: 300,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <Grid container sx={{ padding: "5px", paddingTop: "0px" }}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {isLoggedIn && (
                        <a
                          href="/accountPage"
                          className="dropdown-toggle"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            marginBottom: "0.2rem",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{
                              verticalAlign: "middle",
                              marginbottom: "0.3rem",
                            }}
                          />{" "}
                          {user?.name?.split(" ", 3).join(" ") ?? "Account"}
                        </a>
                      )}
                    </div>
                    <div
                      // className="location-selector"
                      // style={{ flexBasis: "25%" }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <StyledFormControl
                        fullWidth
                        margin="dense"
                        sx={{
                          zIndex: 99999,
                          width: "100%",
                          ".MuiInputBase-root": {
                            height: 40,
                            fontSize: "0.875rem",
                          },
                          ".MuiOutlinedInput-input": {
                            padding: "10px 14px",
                          },
                          ".MuiInputLabel-root": { fontSize: "0.875rem" },
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Locations
                        </InputLabel>
                        <Select
                          id="demo-multiple-name"
                          value={
                            !selectedLocation 
                              ? ""
                              : selectedLocation?.code ??
                                locationsStore[0]?.code
                          }
                          onChange={handleLocationChange}
                          input={<OutlinedInput label="Location" />}
                          fullWidth
                          sx={{
                            zIndex: 9999,
                            // borderRadius: "10px",
                            ".MuiSvgIcon-root": { fontSize: "1rem" }, // Adjust the dropdown icon size if necessary
                          }}
                          required
                          renderValue={(selectedCode) => {
                            const selected = locationsStore.find(
                              (location: any) => location.code === selectedCode
                            );
                            return (
                              <>
                                <LocationOnIcon
                                  sx={{
                                    color: "red",
                                    marginRight: "5px",
                                  }}
                                />
                                <Tooltip
                                  title={
                                    selectedLocation?.displayName +
                                    " " +
                                    selectedLocation?.address
                                  } // arrow
                                >
                                  <a>{`My Store: ${
                                    selected?.displayName +
                                    " " +
                                    selected?.address
                                  }`}</a>
                                </Tooltip>
                              </>
                            );
                          }}
                        >
                          {locationsStore &&
                            locationsStore.map((location: Location) => (
                              <MenuItem
                                key={location.code}
                                value={location.code}
                                sx={{
                                  color: "black",
                                  backgroundColor: "transparent",
                                  fontSize: "0.875rem",
                                }}
                              >
                                    { location?.displayName + " " + location?.address }
                              </MenuItem>
                            ))}
                        </Select>
                      </StyledFormControl>
                    </div>
                  </Grid>
                </Grid>

                {menu.map((page: any, index: any) =>
                  page.name === "Shop" ? (
                    <React.Fragment key={index}>
                      <ListItemButton onClick={handleClick}>
                        <ListItemText primary={page.label} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {brands?.map((brand: any, index: number) => {
                            return (
                              <ListItemButton
                                key={index}
                                sx={{ pl: 4 }}
                                onClick={() => {
                                  handleBrandSelection(brand.Code);
                                  handleCloseNavMenu();
                                }}
                              >
                                <ListItemText primary={brand.Code} />
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ) : page.name === "Locations" ? (
                    <React.Fragment key={index}>
                      <ListItemButton onClick={handleClickLocations}>
                        <ListItemText primary={page.label} />
                        {openLocations ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={openLocations} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {locationsStore?.map(
                            (location: any, index: number) => {
                              return (
                                <ListItemButton
                                  key={index}
                                  sx={{ pl: 4 }}
                                  onClick={() => {
                                    navigate("/location/"+location?.name?.replace(" ","-"));
                                    handleCloseNavMenu();
                                  }}
                                >
                                  <ListItemText
                                    primary={location?.displayName}
                                  />
                                </ListItemButton>
                              );
                            }
                          )}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ) : page.name === "Account" ? (
                    <ListItemButton
                      onClick={() => {
                        handleCloseNavMenu();
                        navigate(page.url);
                      }}
                      key={index}
                    >
                      <ListItemText
                        primary={`${page.label}${
                          isLoggedIn ? `: ${user?.name?.split(" ", 1)}` : ""
                        }`}
                      />
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      key={index}
                      onClick={() => {
                        if (page.name === "Logout") {
                          localStorage.removeItem("user");
                        }
                        handleCloseNavMenu();
                        navigate(page.url);
                      }}
                    >
                      <ListItemText primary={page.name} />
                    </ListItemButton>
                  )
                )}
              </List>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MobileHeader;
