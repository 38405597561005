import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Dashboard from "./Dashboard";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import { store } from "./store";
import { Provider } from "react-redux";

import lightThemeOptions from "./theme/lightThemeOptions";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import NotFound404Page from "./pages/GlobalPages/NotFound404Page";
let lightTheme = createTheme(lightThemeOptions);
lightTheme = responsiveFontSizes(lightTheme);

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(<Route path="/*"  element={<Dashboard />} />)
  );
  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
          {/* <GoogleOAuthProvider
            clientId={ 
              "894661361392-pi86vu1fr98pbo074j35q85gap68ch3j.apps.googleusercontent.com"
            }
          > */}
            <RouterProvider router={router} />
          {/* </GoogleOAuthProvider> */}
      </ThemeProvider>
    </Provider>
  );
}

export default App;
