import { useState, useEffect } from 'react';
import { DiscountsApiResponse, CartItem, OptionalServices } from '../models/app';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCarts, editCartItem } from '../store/ducks/app';
import { RootState } from '../store/ducks';
import { getDiscounts } from "../services/GetDiscounts";
import { applyDiscount, updateDiscount } from "../store/ducks/app";


export function useCart(isCart: boolean) {
  const dispatch = useDispatch();

  const cart = useSelector((state: RootState) => state?.store?.cart);

  const [cartLoading, setLoading] = useState(true);
  const [cartError, setError] = useState<string | null>(null);  
  const [servicesTotal, setServicesTotal] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalPriceWithoutTax, setTotalPriceWithoutTax] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [tax1, setTax1] = useState<number>(0);
  const [tax2, setTax2] = useState<number>(0);
  const [tax1Description, setTax1Description] = useState<string>("");
  const [tax2Description, setTax2Description] = useState<string>("");

  const discount = useSelector((state: any) => state?.store?.discountApplied); 
  const [discountApplied, setDiscountApplied] = useState<boolean>(discount);
  const discountPercentage = useSelector((state: any) => state?.store?.discountPercentage); 
  const [discountAmount, setDiscountAmount] = useState<number>(discountPercentage);
  const [cartItems, setCartItems] = useState<CartItem[]>(() => {
  const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });

  useEffect(() =>
    {
      if(isCart){
        fetchDiscounts();
      }
    }, []);
  
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItems));
    // console.log("cart: ", cart);
    calculateTotal();
    calculateTaxesTotal(cartItems);
  }, [cartItems]);

  useEffect(() => {
    // console.log("discountApplied: ", discountApplied);
    calculateTotal(discountApplied);
    setDiscountApplied(discountApplied);
  }, [discountApplied]);

  const removeFromCart = (id: string) => {
    const updatedCartItems = cartItems.filter((item: CartItem) => item.id !== id);
    dispatch(removeFromCarts(id));
    setCartItems(updatedCartItems);
  };

  const updateCart = (cartItem: CartItem) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === cartItem.id ? { ...item, ...cartItem } : item
    );
    dispatch(editCartItem({ id: cartItem.id, data: { ...cartItem } }));
    setCartItems(updatedCartItems);
  };
  

  const calculateTotal = (discountApplieds?:boolean) => {

    var newPrice = cartItems.reduce((acc, curr) => {
      const serviceTotal = calculateServicesTotal(curr.services??[], curr.quantity);
      return acc + (curr.price * curr.quantity) + (serviceTotal);
    }, 0);

    setTotalPriceWithoutTax(newPrice);

    const taxes = calculateTaxesTotal(cartItems);
    const taxes1 = taxes.tax;
    const taxes2 = taxes.tax1;
    const taxes3 = taxes.tax2;
    
    var newPriceWithTaxes = newPrice+taxes1+taxes2+taxes3;

    if(discountApplied){
      const discountAmount = ((discountPercentage*newPriceWithTaxes)/100);
      const priceWithDiscount = newPriceWithTaxes - discountAmount;
      newPriceWithTaxes = priceWithDiscount;
    }
    // console.log("discountApplied: ", discountApplied, "discount: ", discountAmount, "newPrice: ", newPrice)
    setTotalPrice(newPriceWithTaxes);
  };


  const calculateServicesTotal = (services: OptionalServices[], quantity: number) => {
    const total = services.reduce((acc, curr) => {
      const multiplyService = curr.CalcType === "Variable"? quantity : 1;
      return acc + (multiplyService * (curr.Selected ? curr.ServicePriceWithTax : 0))
    }, 0);
    setServicesTotal(total)
    return total
    
  };

  const calculateTaxesTotal = (items: CartItem[]) => {
    const tax1Total = items.reduce((acc, item) => {
      const tax1 = parseFloat(item.salesTax??"") || 0;
      // console.log(item.salesTax, " tax1", tax1);
      return acc + (item.quantity * tax1);
    }, 0);

    setTax(tax1Total);

    const tax2Total = items.reduce((acc, item) => {
      const tax2 = parseFloat(item.additionalTax1??"") || 0;
      // console.log(item.additionalTax1, " tax2", tax2);
      return acc + (item.quantity * tax2);
    }, 0);

    setTax1(tax2Total);
    setTax1Description(items[0]?.additionalTax1Caption??"");
    
    const tax3Total = items.reduce((acc, item) => {
      const tax3 = parseFloat(item.additionalTax2??"") || 0;
      // console.log(item.additionalTax2, " tax3", tax3);
      return acc + (item.quantity * tax3);
    }, 0);

    setTax2(tax3Total);
    setTax2Description(items[0]?.additionalTax2Caption??"");

    // console.log({tax: tax1Total, tax1: tax2Total, tax2: tax3Total});
    return {tax: tax1Total, tax1: tax2Total, tax2: tax3Total};
  };


  const fetchDiscounts = async () => {
    try {
      setLoading(true);
      
      const apiResponse:DiscountsApiResponse  = await getDiscounts();
      const values:number = apiResponse.Data.discountPercentage;
      if(values>=0){
        setDiscountAmount(values);
      }
      dispatch(applyDiscount(false));
      dispatch(updateDiscount(values));
    } catch (error) {
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  return { updateCart, totalPrice,servicesTotal, totalPriceWithoutTax, cartItems, setCartItems, discountAmount, discountApplied, setDiscountApplied, removeFromCart, calculateTotal, calculateTaxesTotal, tax, tax1, tax2, tax1Description, tax2Description };
}

