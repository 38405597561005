export const Endpoints = {
     GetToken: "https://api.tirezoneusa.com/api/data/gettoken",
     GetHomePageFilters: "api/data/GetHomePageFilters",
     GetProducts: "api/data/GetAdvancedSearchData",
     GetProductsRear: "api/data/GetCustomAdvancedSearchData",
     // GetLocations: "api/data/GetLocations",
     GetLocations: window.location.protocol + "//" + window.location.host + "/locations.json",
     GetAdditionalServices: "api/data/GetAdditionalServices",
     GetCoupons: "api/data/GetCoupons",
     PlaceOrder: "api/data/CreateOrder",
     // GetBrands: "api/data/GetBrands",
     GetBrands:  window.location.protocol + "//" + window.location.host + "/brands.json",
     GetModelsByBrand: "api/data/GetModelsByBrand",
     GetRawSizesByModel: "api/data/GetRawSizesByBrandModel",
     GetDiscounts: "api/data/GetDiscount",
     getProfileOptions: window.location.protocol + "//" + window.location.host + "/Profiles.json",
     getWheelOptions: window.location.protocol + "//" + window.location.host + "/WheelSize.json",
     getSeasonOptions: window.location.protocol + "//" + window.location.host + "/Season.json",
     getSpeedRatingOptions: window.location.protocol + "//" + window.location.host + "/SpeedRating.json",
     getLoadIndexOptions: window.location.protocol + "//" + window.location.host + "/LoadIndex.json",
     getCatalogItems: window.location.protocol + "//" + window.location.host + "/ShopData.json",
     Signup: "api/user/SignUp",
     Login: "api/user/login",
     LoginGoogle: "api/user/googleSignIn",
     UpdateProfile: "api/user/UpdateUserProfile",
     ResetPassword: "api/user/resetpassword",
     ChangePassword: "api/user/ChangePassword",
     GetUserInvoices: "api/user/GetUserInvoices",
     GetInvoiceDetails: "api/user/GetInvoiceDetails",
     ContactUs: "api/data/ContactUs",
     GetUserSalesOrders: "api/user/GetUserSalesOrders",
     GetSalesOrderDetails: "api/user/GetUserSalesOrderDetails",
}