
export const menuItems = [
  { id: 0, name: "Home", label:"Home" ,  url: "/" },
  { id: 1, name: "Shop", label:"Tire Catalog", url: "/shop" ,children :true},
  { id: 2, name: "Locations", label:"Locations",url: "/" },
  { id:3, name: "Coupons", label:"Coupons",url: "/coupons" },
  {id:4,name:"About",label:"About",url:"/Aboutus"},
  {id:5,name:"Contact",label:"Contact",url:"/contact"},
  {id:7,name:"Sign up",label:"Sign Up",url:"/signup"},
  {id:7,name:"Login",label:"Login",url:"/login"},
];
export const menuItemsAuth = [
  { id: 0, name: "Home", label:"Home" ,  url: "/" },
  { id: 1, name: "Shop", label:"Tire Catalog", url: "/shop" ,children :true},
  { id: 2, name: "Locations", label:"Locations",url: "/" },
  { id:3, name: "Coupons", label:"Coupons",url: "/coupons" },
  {id:4,name:"About",label:"About",url:"/Aboutus"},
  {id:5,name:"Contact",label:"Contact",url:"/contact"},
  {id:6,name:"Account",label:"Account",url:"/accountPage"},
  {id:6,name:"Invoices",label:"Invoices",url:"/myOrders"},
  {id:6,name:"Sales Orders",label:"Orders",url:"/mySalesOrders"},
  {id:7,name:"Logout",label:"Logout",url:"/"},
];

export const brands = [
  {
    "Code": "ACCELERA",
    "Description": "ACCELERA"
  },
  {
    "Code": "ADVANTA",
    "Description": "ADVANTA"
  },
  {
    "Code": "AMERICUS",
    "Description": "AMERICUS"
  },
  {
    "Code": "ARROYO",
    "Description": "ARROYO"
  },
  {
    "Code": "BF GOODRICH",
    "Description": "BF GOODRICH"
  },
  {
    "Code": "BRIDGESTONE",
    "Description": "BRIDGESTONE"
  },
  {
    "Code": "CENTARA",
    "Description": "CENTARA"
  },
  {
    "Code": "CONTINENTAL",
    "Description": "CONTINENTAL"
  },
  {
    "Code": "COOPER",
    "Description": "COOPER"
  },
  {
    "Code": "DEESTONE",
    "Description": "DEESTONE"
  },
  {
    "Code": "DELINTE",
    "Description": "DELINTE"
  },
  {
    "Code": "DUNLOP",
    "Description": "DUNLOP"
  },
  {
    "Code": "FALKEN",
    "Description": "FALKEN"
  },
  {
    "Code": "FIRESTONE",
    "Description": "FIRESTONE"
  },
  {
    "Code": "FUZION",
    "Description": "FUZION"
  },
  {
    "Code": "GALAXY",
    "Description": "GALAXY"
  },
  {
    "Code": "GENERAL",
    "Description": "GENERAL"
  },
  {
    "Code": "GLADIATOR",
    "Description": "GLADIATOR"
  },
  {
    "Code": "GOODYEAR",
    "Description": "GOODYEAR"
  },
  {
    "Code": "GTRADIAL",
    "Description": "GTRADIAL"
  },
  {
    "Code": "HANKOOK",
    "Description": "HANKOOK"
  },
  {
    "Code": "HERCULES",
    "Description": "HERCULES"
  },
  {
    "Code": "KELLY",
    "Description": "KELLY"
  },
  {
    "Code": "KUMHO",
    "Description": "KUMHO"
  },
  {
    "Code": "LANDSAIL",
    "Description": "LANDSAIL"
  },
  {
    "Code": "MASTERCRAFT",
    "Description": "MASTERCRAFT"
  },
  {
    "Code": "MICHELIN",
    "Description": "MICHELIN"
  },
  {
    "Code": "NANKANG",
    "Description": "NANKANG"
  },
  {
    "Code": "NEBULA",
    "Description": "NEBULA"
  },
  {
    "Code": "NEOTERRA",
    "Description": "NEOTERRA"
  },
  {
    "Code": "NEXEN",
    "Description": "NEXEN"
  },
  {
    "Code": "NITTO",
    "Description": "NITTO"
  },
  {
    "Code": "PIRELLI",
    "Description": "PIRELLI"
  },
  {
    "Code": "PROCOMP",
    "Description": "PROCOMP"
  },
  {
    "Code": "ROADMASTER",
    "Description": "ROADMASTER"
  },
  {
    "Code": "SUMITOMO",
    "Description": "SUMITOMO"
  },
  {
    "Code": "TBB",
    "Description": "TBB"
  },
  {
    "Code": "TOYO",
    "Description": "TOYO"
  },
  {
    "Code": "UNIROYAL",
    "Description": "UNIROYAL"
  },
  {
    "Code": "YOKOHAMA",
    "Description": "YOKOHAMA"
  }
]
export const currentLocations = [
    {
      name: "Tire Zone Covina",
      phone: "(626) 338-3500",
      address: "15940 E San Bernardino Rd #A, Covina, CA 91722",
      location: "https://www.google.com/maps?q=34.0894,-117.935&z=13"
    },
    {
      name: "Tire Zone Glendora",
      phone: "(626) 963-4567",
      address: "321 W Arrow Hwy., Glendora, CA 91740",
      location : "https://www.google.com/maps?q=34.1068,-117.869&z=13",
    },
    {
      name: "Tire Zone Ontario",
      phone: "(909) 395-0005",
      address: "940 West Holt Blvd., Ontario, CA 91762",
      location : "https://www.google.com/maps?q=34.0636,-117.667&z=13"
    },
  ];

  export const items = [
    {
      title: "This Week Only for World Premier",
      subtitle: "New Top Product\nHigh Quality",
      buttonLabel: "Shop Now",
      buttonLink: "https://tirezoneonline.com/",
    },
    {
      title: "Off-Road Adventure Tires",
      subtitle: "Explore the Great Outdoors",
      buttonLabel: "Discover Off-Road Tires",
      buttonLink: "https://tirezoneonline.com/",
    },
    {
      title: "Performance and Sports Tires",
      subtitle: "Maximize Your Driving Experience",
      buttonLabel: "View Performance Tires",
      buttonLink: "https://tirezoneonline.com/",
    },
    // Add more items as needed
  ];

  // Replace this with actual data for the right side

  export const filterOptions = [
    { id: 0, name: "Year", selectOptions: ["2024","2023","2022","2021","2020","2019","2018" ] },
    { id: 1, name: "Make", selectOptions: ["Make 01", "Make 02", "Make 03"] },
    { id: 2, name: "Model", selectOptions: ["Model A", "Model B", "Model C"] },
    {
      id: 3,
      name: "Trim",
      selectOptions: ["Option 1", "Option 2", "Option 3"],
    },
    {
      id: 4,
      name: "Trim Size",
      selectOptions: ["Option 1", "Option 2", "Option 3"],
    },
    {
      id: 5,
      name: "Season",
      selectOptions: ["Option 1", "Option 2", "Option 3"],
    },
    // Add more filter options as needed
  ];
  export const filtertireOptions = [
    {
      id: 0,
      name: "Width",
      selectOptions: ["100", "105", "115", "120", "125"],
    },
    { id: 1, name: "Profile", selectOptions: ["65", "75", "100"] },
    { id: 2, name: "Wheel Size", selectOptions: ['4"', '5"'] },
    {
      id: 3,
      name: "Season",
      selectOptions: [
        "All Tires",
        "All Weather",
        "All Season",
        "Summer",
        "Winter",
      ],
    },
    // {
    //   id: 4,
    //   name: "Speed Rating",
    //   selectOptions: ["Option 1", "Option 2", "Option 3"],
    // },
    // {
    //   id: 5,
    //   name: "Load Index",
    //   selectOptions: ["Option 1", "Option 2", "Option 3"],
    // },
    // Add more filter options as needed
  ];
    

  export const SearchOptions = [
    { id: 0, name: "By Tire Size", filterOptions: filtertireOptions },
    { id: 1, name: "By Raw Size", filterOptions: filterOptions },
  ];
