import { useEffect, useState } from 'react';
import { PlaceOrder } from '../services/PlaceOrder';
import { CartItem, OptionalServices, OrderBody, OrderItem, OrdersApiResponse } from '../models/app';
import { RootState } from '../store/ducks';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../store/ducks/app';


export interface PlaceOrderQueryParams {
  isCheckout: boolean,
  note: string,
}

export const usePlaceOrder = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state?.store?.user);
  const selectedLocationStore = useSelector((state: RootState) => state?.store?.selectedLocation);
  useEffect(()=>{
    setuserStorage(user);
  }, [user])
  
  const [orders, setOrders] = useState<OrderItem[] | null>(null);
  const [ordersLoading, setLoading] = useState(false);
  const [ordersError, setError] = useState<string | null>(null);
  const [cartStored, setCartStored] = useState<any>(((state: RootState) => state?.store?.cart) ??[]);
  const [userStorage, setuserStorage] = useState(user);
  const discount = useSelector((state: any) => state?.store?.discountApplied); 
  
  const placeOrder = async (userIdProp: string, note: string) => {

    const cartItems = cartStored??JSON.parse(localStorage.getItem('cart')??"[]")??[];
    // console.log("order storage: ", userStorage, "user", user);
    // console.log("order userIdProp:", userIdProp);

    let userFinal = (userIdProp!==null && userIdProp.length!==0)? userIdProp : ((userStorage!== null) && (userStorage!== "") && (userStorage!== "guest"))? userStorage.id: userStorage;
    // console.log("userFinal", userFinal);

    if(userFinal!==null && userFinal!=="" && userFinal !== "guest"){
        try {
      
          setLoading(true);  
          let orderItems: OrderItem[] = [];
    
          cartItems.forEach((item: CartItem) => {
            orderItems.push({
              itemId: item.id,
              quantity: item.quantity,
              webTireID: item.id,
            });
            
            if((userStorage !== "") && (userStorage !== "guest") && (userStorage.showAdditionnalServices === true)){
              
              item.services?.filter((service:OptionalServices) => service.Selected).forEach((service) => {
                orderItems.push({
                  itemId: service.ServiceItemId,
                  quantity: service.CalcType !== "Fixed" ? item.quantity:1,
                  webTireID: item.id,
                });
              });
            }
          });
    
          // console.log(orderItems);
          
          var orderBody: OrderBody = {
            customerId: userFinal, //??"8a4e2e23-f8d2-ee11-904c-7c1e520c7fc8"
            locationCode:selectedLocationStore.code,
            cashDiscount:discount,
            webOrder: true,
            salesOrderLines: orderItems,
          };

          if(note.length > 0){
            orderBody = {...orderBody, externalDocumentNumber: note} 
          }

          const apiResponse:OrdersApiResponse  = await PlaceOrder(orderBody);
          // console.log(orderBody, apiResponse);
          const responseSuccess: number = apiResponse.Success;
          // console.log(responseSuccess);
          if(responseSuccess === 1){
            window.location.href = "/confirmOrder";
            dispatch(clearCart());
          }
    
        } catch (error) {
          setError('Error fetching data');
        } finally {
          setLoading(false);
        }
      }
     
    else if(userStorage!==null && (userStorage === "" || userStorage === "guest")){
      const params: PlaceOrderQueryParams = {
        isCheckout: true,
        note: note
      };
        const queryString = new URLSearchParams(params as any).toString();
        navigate(`/signup?${queryString}`);
    }
    // console.log(userStorage);
  };
  return { placeOrder, orders, ordersLoading, ordersError };
};    