import {Box, FormControl, Grid, TextField} from '@mui/material';
import {useEffect, useState} from 'react';
import OptionalServicesModal from '../../modal/OptionalServicesModal';
import {OptionalServices} from '../../../models/app';
import CloseIcon from '@mui/icons-material/Close';

const CartItem = ({updateCart, product, removeFromCart, calculateTotal, hasAdditionalTax, user}: any) => {
    const [qty, setQty] = useState(product?.quantity);

    useEffect(() => {
        setQty(product?.quantity);
    }, [product]);


    const [options, setOptions] = useState<OptionalServices[]>(product.services ?? []);
    const [open, setOpen] = useState(false);


    const handleChangeQty = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQty = parseInt(event.target.value);
        if (!isNaN(newQty) && newQty > 0) {
            setQty(newQty);
            product.quantity = newQty;
            updateCart(product);
        }
    };


    const calculateServicesTotal = (services: OptionalServices[], quantity: number) => {
        return services.reduce((acc, curr) => {
            const multiplyService = curr.CalcType === "Variable" ? quantity : 1;
            return acc + (multiplyService * (curr.Selected ? curr.ServicePriceWithTax : 0))
        }, 0);
    };

    const [showLabel] = useState(true);

    if (true) {
        return (
            <Grid
                container
                spacing={1}
                alignItems="center"
                style={{
                     paddingBottom: "15px",
                    backgroundColor:"white",
                    borderBottom:"10px solid lightgrey",
                    marginTop: 2,
                }}
            >
                {/* Product Image */}
                <Grid item xs={4} sm={3} style={{display: 'flex', alignItems: 'center'}}>
                    <Box
                        onClick={() => removeFromCart(product?.id)}
                        sx={{
                            marginLeft: {xs: '1px', sm: '30px'},
                            width: "35px",
                            height: "35px",
                            lineHeight: "35px",
                            fontSize: "14px",
                            color: "#141417",
                            fontWeight: "bold",
                            border: "1px solid #e5e5e5",
                            textAlign: "center",
                            borderRadius: "50%",
                            cursor: "pointer",
                            transition: "all 300ms ease",
                        }}
                    >
                        <CloseIcon style={{fontSize: "20px"}}/>
                    </Box>
                    <Box
                        sx={{
                            p: 1
                        }}
                    >
                        <img
                            src={
                                product?.image ??
                                require("../../../assets/images/Default-removebg-preview.png")
                            }
                            alt={product?.name}
                            style={{
                                width: "auto", height: "50px", cursor: "pointer"
                            }}
                        />
                    </Box>
                </Grid>
                {/* Product Name */}
                <Grid item xs={8} sm={3} mt={0} >
                    {showLabel && (
                        <div
                            style={{
                                fontSize: "12px",
                                marginBottom: "5px",
                                color: "#333",
                            }}
                        >
                            Product Name
                        </div>
                    )}
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            color: "#333",
                        }}
                    >
                        {product?.name}
                    </div>
                    <div
                        style={{
                            fontSize: "14px",
                            color: "#777",
                        }}
                    >
                        {product?.description}
                    </div>
                </Grid>

                {/* Additional Tax */}
                <Grid item xs={4} sm={1}  style={{ display : 'flex', flexDirection : 'row'}}>
                    {showLabel && product.additionalTax1 && hasAdditionalTax && (
                        <div
                            style={{
                                fontSize: "12px",
                                marginBottom: "5px",
                                color: "#333",
                                paddingLeft : '10px',
                                textAlign: "center", // Center text for mobile view
                            }}
                        >
                            FET :
                        </div>
                    )}
                    {product.additionalTax1 && hasAdditionalTax && (

                        <div
                            style={{
                                fontSize: "14px",
                                color: "#333",
                                paddingLeft : '2px',
                                textAlign: "center", // Center for mobile view
                            }}
                        >
                            {`$${parseFloat(product.additionalTax1).toFixed(2)}`}
                        </div>
                    )}
                </Grid>


                {/* Quantity */}
                <Grid item xs={4} sm={1} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <FormControl>
                        <TextField
                            id="quantity-select"
                            label="Qty"
                            type="number"
                            value={qty}
                            onChange={handleChangeQty}
                            InputProps={{
                                sx: {borderRadius: "5px", maxWidth: 80},
                            }}
                            InputLabelProps={{shrink: true}}
                            size="small"
                            style={{textAlign: "center"}} // Center for mobile view
                        />
                    </FormControl>
                </Grid>

                {/* Price */}
                {/* <Grid item xs={4} sm={2}   style={{ display : 'flex', flexDirection : 'row'}}>
                    {showLabel && (
                        <div
                            style={{
                                fontSize: "14px",
                                marginBottom: "5px",
                                color: "#333",
                                textAlign: "center", // Center text for mobile view
                            }}
                        >
                            Price :
                        </div>
                    )}
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            paddingLeft : '2px',
                            color: "#333",
                            textAlign: "center", // Center for mobile view
                        }}
                    >
                        {`$${(
                            (product?.price + (parseFloat(product.additionalTax1) ?? 0)) *
                            (qty || product?.quantity)
                        ).toFixed(2)}`}
                    </div>
                </Grid> */}
                <Grid item xs={4} sm={2} mt={0} >
                  
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            color: "#333",
                        }}
                    >
                        Price :{`$${(
                            (product?.price + (parseFloat(product.additionalTax1) ?? 0)) *
                            (qty || product?.quantity)
                        ).toFixed(2)}`}
                    </div>
                    <div
                        style={{
                            fontSize: "14px",
                            color: "red",
                        }}
                    >
                      {product.showMessage ? '(Please allow 1-2 business days for Tire availability before pickup)' : ''}
                    </div>
                </Grid>
                {/* Selected Services */}
                {process.env.REACT_APP_WHOLESALE_FLAG === 'false' && <Grid item xs={12} sm={2} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    {((user !== "") &&
                        ((user === "guest") || user.showAdditionnalServices === true)) && (
                        <div
                            className="theme-btn theme-btn-eight"
                            style={{
                                fontSize: "14px",
                                cursor: "pointer",
                                borderRadius: "10px",
                                backgroundColor: "#d32f2f",
                                color: "#fff",
                                padding: "10px",
                                textAlign: "center",
                                margin: "auto", // Center for mobile view
                            }}
                            onClick={() => setOpen(!open)}
                        >
                            {`Selected Services: $${calculateServicesTotal(
                                product.services,
                                qty
                            ).toFixed(2)}`}
                        </div>
                    )}
                </Grid>}

                {/* Optional Services Modal */}
                {options.length !== 0 && (
                    <OptionalServicesModal
                        product={product}
                        updateCart={updateCart}
                        calculateTotal={calculateTotal}
                        open={open}
                        setOpen={setOpen}
                        fields={options}
                        setOptions={setOptions}
                        isCart={true}
                    />
                )}
            </Grid>


        );
    }

    return (
        <>
            <tr>
                <td colSpan={4} className="prod-column">
                    <div className="column-box">
                        <div
                            className="remove-btn"
                            onClick={() => removeFromCart(product?.id)}
                            style={{
                                position: 'absolute',
                                left: '0px',
                                top: '50%',
                                transform: 'translateY(-30%)',
                                width: '35px',
                                height: '35px',
                                lineHeight: '30px',
                                fontSize: '10px',
                                color: '#141417',
                                fontWeight: '900',
                                border: '1px solid #e5e5e5',
                                textAlign: 'center',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                transition: 'all 500ms ease',
                                // '&:hover': {
                                //   color: '#E7131A',
                                // },
                            }}
                        >
                            <CloseIcon/>
                        </div>

                        <div className="prod-thumb" style={{width: "110px", height: "110px"}}>
                            <img src={product?.image ?? require("../../../assets/images/Default-removebg-preview.png")}
                                 alt={product?.name} width={"110px"} height={"110px"}
                                 style={{maxWidth: "110px", maxHeight: "110px"}}/>
                        </div>
                        <div className="prod-title" style={{maxWidth: "450px", paddingRight: "15px"}}>
                            {product?.name} <br/> {product?.description}
                        </div>
                    </div>
                </td>
                <td className="price">${product?.price.toFixed(2)}</td>
                {product.additionalTax1 && hasAdditionalTax &&
                    <td className="price">{`$${parseFloat(product.additionalTax1).toFixed(2)}`}</td>
                }
                <td className="qty">
                    <FormControl fullWidth
                                 sx={{borderRadius: "5px", color: "black", marginBottom: "5px", width: "100%"}}>
                        <TextField
                            id="quantity-select"
                            label="Qty"
                            type="number"
                            value={qty}
                            onChange={handleChangeQty}
                            InputProps={{sx: {borderRadius: "5px", color: "black", maxWidth: 80}}}
                            InputLabelProps={{shrink: true}}
                            size='small'
                        />
                    </FormControl>

                </td>
                <td className="sub-total">${(((product?.price + (parseFloat(product.additionalTax1) ?? 0)) * (qty ? qty : product?.quantity))).toFixed(2)}</td>
                {((user !== "") && ((user === "guest") || (user.showAdditionnalServices === true))) && <td>
                    <div className="theme-btn theme-btn-eight"
                         style={{fontSize: "14px", cursor: "pointer", borderRadius: "10px"}}
                         onClick={() => setOpen(!open)}>{`selected Services: $${(calculateServicesTotal(product.services, qty)).toFixed(2)}`}</div>
                </td>}
            </tr>
            {options.length !== 0 &&
                <OptionalServicesModal product={product} updateCart={updateCart} calculateTotal={calculateTotal}
                                       open={open} setOpen={setOpen} fields={options} setOptions={setOptions}
                                       isCart={true}/>}
        </>
    );
}
export default CartItem;
