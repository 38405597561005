import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  TableCell,
  Box,
} from "@mui/material";
import Loader from "../../components/UI/loader/Loader";
import { useGetUserSalesOrders } from "../../hooks/useGetUserSalesOrders";
import { RootState } from "../../store/ducks";
import { Order } from "../../models/app";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

// function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
//   if (!a[orderBy] || !b[orderBy]) return 0; // Handle undefined values
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator<Key extends keyof Order>(
//   order: 'asc' | 'desc',
//   orderBy: Key,
// ): (a: Order, b: Order) => number {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
//   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

function formatDate(inputDate?: string): string {
  if (!inputDate) {
    return 'N/A';
  }
  const date = new Date(inputDate);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export default function UserOrdersPage() {
  const userId: string = useSelector((state: RootState) => state.store?.userSystemId);
  const { loading, orders } = useGetUserSalesOrders(userId);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  // const [orderBy, setOrderBy] = useState<keyof Order>('orderDate');

  // const handleRequestSort = (property: keyof Order) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // const createSortHandler = (property: keyof Order) => () => {
  //   handleRequestSort(property);
  // };



  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const columns: GridColDef<any>[] = [
    {
      field: 'ViewDetails',
      headerName: 'View Details',
      width: 200,
      filterable:false,
      sortable:false,
      renderCell: (params) => (
        <TableCell>
          <Link to={`/salesOrderDetails/${params.row.id}`}>View Details</Link>
        </TableCell>
      ),
    },
    {
      field: 'locationCode',
      headerName: 'Location Code',
      width: 200,
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      width: 200,
    },
    {
      field: 'number',
      headerName: 'Order Number',
      width: 200,
    },
    {
      field: 'documentStatus',
      headerName: 'Status',
      width: 200,
    },
    {
      field: 'externalDocumentNumber',
      headerName: 'Po Number',
      width: 200,
    },
    {
      field: 'totalAmountIncludingTax',
      headerName: 'Total Price',
      width: 200,
    },
    
  ];

return (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>User Orders</title>
      <meta name="description" content="User Orders Page" />
      <meta name="keywords" content="User Orders Page" />
      <meta name="author" content="TireZones" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {orders && orders.map((order: Order) => (
        <meta
          key={order.id}
          name="order"
          content={`Order Number: ${order?.number}, Date: ${formatDate(order.orderDate)}, Status: ${order.documentStatus}, Total: ${order.totalAmountIncludingTax}`}
        />
      ))}
    </Helmet>
    
    {loading && <Loader />}
    {!loading && <Box sx={{ height: 650, width: '100%' }}>
      <DataGrid
      
        rows={orders}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          sorting: {
            sortModel: [{ field: 'number', sort: 'desc' }],
          },
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        // disableColumnFilter
                disableColumnSelector
        disableDensitySelector
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>}

    {!loading && (<></>
      // <TableContainer component={Paper} style={{padding: "25px"}}>
      //   <Table>
      //     <TableHead>
      //       <TableRow>
      //         <TableCell>View Details</TableCell>
      //         <TableCell>StoreName</TableCell> {/* Placeholder as your interface does not specify this */}
      //         <TableCell sortDirection={orderBy === 'orderDate' ? order : false}>
      //           <TableSortLabel
      //             active={orderBy === 'orderDate'}
      //             direction={orderBy === 'orderDate' ? order : 'asc'}
      //             onClick={createSortHandler('orderDate')}
      //           >
      //             Date Ordered
      //           </TableSortLabel>
      //         </TableCell>
      //         <TableCell>Order Number</TableCell>
      //         <TableCell>Document Status</TableCell>
      //         <TableCell>PO Number</TableCell> {/* Placeholder as your interface does not specify this */}
      //         <TableCell>Total Price</TableCell>
      //       </TableRow>
      //     </TableHead>
      //     <TableBody>
      //     {stableSort(orders, getComparator(order, orderBy))
      //       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      //       .map((order: Order) => (
      //         <TableRow key={order.id}>
      //           <TableCell>
      //             <Link to={`/salesOrderDetails/${order.id}`}>View Details</Link>
      //           </TableCell>
      //           <TableCell>{order.locationCode?? ""}</TableCell>
      //           <TableCell>{order.orderDate ? formatDate(order.orderDate) : ""}</TableCell>
      //           <TableCell>{order.number ?? ""}</TableCell>
      //           <TableCell>{order.documentStatus ?? ""}</TableCell>
      //           <TableCell>{order.externalDocumentNumber ?? ""}</TableCell>
      //           <TableCell>${order.totalAmountIncludingTax?.toFixed(2) ?? ""}</TableCell>
      //         </TableRow>
      //     ))}
      //   </TableBody>
      //   </Table>
      //   <TablePagination
      //     rowsPerPageOptions={[10, 15, 30]}
      //     component="div"
      //     count={orders.length}
      //     rowsPerPage={rowsPerPage}
      //     page={page}
      //     onPageChange={handleChangePage}
      //     onRowsPerPageChange={handleChangeRowsPerPage}
      //   />
      //   </TableContainer>
      )}
    </div>
  );
}