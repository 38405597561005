import React, {useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CartItem, Location, OptionalServices, Product, Stock} from "../../models/app";
import {
    Box,
    Dialog,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {addToCart} from "../../store/ducks/app";
import {useGetAdditionalServices} from "../../hooks/useGetAdditionalServices";
import {useCart} from "../../hooks/useCart";
import {RootState} from "../../store/ducks";
import ReactHtmlParser from "react-html-parser";
import ClearIcon from "@mui/icons-material/Clear";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Alert from "../../components/UI/alert/Alert";
import OptionalServicesModal from "../../components/modal/OptionalServicesModal";
import {Helmet} from "react-helmet";
import
{
  updateSelectedLocation,
  updateUserSystemId,
} from "../../store/ducks/app";
interface TireDetailsPageProps {
    item: Product;
    showExternal: boolean;
    handleAddToCart?: (item: any) => void;
    getLocationsQuantities: (product: Product, location?: Location , showExternal? : boolean) => Stock[];
}

const TireDetailsPage: React.FC<TireDetailsPageProps> = ({item, getLocationsQuantities,showExternal}: TireDetailsPageProps) => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const locationsStore = useSelector((state: RootState) => state?.store?.locations);
    const selectedLocationStore = useSelector((state: RootState) => state?.store?.selectedLocation);
    const userSystemIdStore = useSelector((state: RootState) => state?.store?.userSystemId);

    const [locations, setLocations] = useState<Location[]>(locationsStore);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(selectedLocationStore);
    const [userSystemId, setUserSystemId] = useState(userSystemIdStore);
    const [descriptionTab, setDescriptionTab] = useState("tab");
    const [specsTab, setSpecsTab] = useState("tab");
    const [reviewsTab, setReviewsTab] = useState("tab");
    const [activeTab, setActiveTab] = useState(false);
    const [activeTabClass, setActiveTabClass] = useState("tab-btn active-btn p_relative d_iblock fs_18 font_family_inter lh_20 float_left fw_medium z_1 mr_35 tran_5");
    const [nonActiveTabClass, setNonActiveTabClass] = useState("tab-btn p_relative d_iblock fs_18 font_family_inter lh_20 float_left fw_medium z_1 mr_35 tran_5");
    const [quantity, setQuantity] = useState(4);
    const [options, setOptions] = useState<OptionalServices[]>([]);
    const [open, setOpen] = useState(false);
    const [stock, setStock] = useState<Stock[]>(getLocationsQuantities(item));
    const initialLocationIndex = stock.findIndex((oneStock: Stock) => oneStock.locationObj.guestCutomerId === selectedLocation?.guestCutomerId).toString();
    const [locationQuantity, setLocationQuantity] = useState<string>(initialLocationIndex);

    // const [stock, setStock] = useState<Stock[]>([]);
  
    // const [locationQuantity, setLocationQuantity] = useState<string>("0");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const totalQuantity = stock
    .filter(currentStock => !currentStock.externalInventory) // Exclude items where external is true
    .reduce((acc, currentStock) => acc + currentStock.qty, 0);

    const [newCartItem, setNewCartItem] = useState<CartItem>(
        {
            locationCode: selectedLocation?.code ?? selectedLocationStore?.code,
            id: item?.SystemId ?? "",
            no: item?.No ?? "",
            name: item?.Model ?? "Product Name",
            quantity: quantity ?? 0,
            image: item?.Image_Source ?? "../../assets/images/product/blog-1.jpg",
            description: item?.Description ?? "",
            price: item?.UnitPrice ?? 0,
            salesTax: item?.salesTax,
            additionalTax1: item?.additionalTax1,
            additionalTax2: item?.additionalTax2,
            additionalTax1Caption: item?.additionalTax1Caption,
            additionalTax2Caption: item?.additionalTax2Caption,
            showMessage : item[selectedLocationStore.locationCaption.charAt(0).toUpperCase() + selectedLocationStore.locationCaption.slice(1) + "Qty"] == 0,

            services: []
        });

    const {optionalServices, optionalServicesLoading, optionalServicesError} =
        useGetAdditionalServices(item?.SystemId, `1`, "");

    const {totalPrice, updateCart, cartItems, setCartItems, calculateTotal, removeFromCart} =
        useCart(false);

    const cart = useSelector((state: RootState) => state?.store?.cart);
    const user = useSelector((state: RootState) => state?.store?.user);

    const isUser = (user !== "" && user !== "guest")




    useEffect(() => {
        // var items= getLocationsQuantities(item,undefined,showExternal);
        // setStock(items);
            // (oneStock.locationObj.guestCutomerId !== stock[parseInt(locationQuantity, 10)]?.locationObj.guestCutomerId) &&
            // <MenuItem key={index} disabled={oneStock.qty === 0}
            //           value={index.toString()} style={{cursor: "default"}}>
            //     {oneStock.locationObj.name} ( {oneStock.qty ?? 0} )
            // </MenuItem>
           // var newStockData = getLocationsQuantities(item,undefined,showExternal);
          //  setStock([...newStockData]);
        buttonState(item, selectedLocationStore);
       
    }, [selectedLocationStore, cart]);

    // const openReviews = () =>{
    //     if(reviewsTab === "tab active-tab"){
    //         setActiveTab(false);
    //         setReviewsTab("tab");
    //     }
    //     else{
    //         setActiveTab(true);
    //         setReviewsTab("tab active-tab");
    //     }
    //     setDescriptionTab("tab");
    //     setSpecsTab("tab");
    // }

    const handleImageClick = () => {
        setImageModalOpen(true);
    };

    const openDescription = () => {
        if (descriptionTab === "tab active-tab") {
            setActiveTab(false);
            setDescriptionTab("tab");
        } else {
            setActiveTab(true);
            setDescriptionTab("tab active-tab");
        }
        setReviewsTab("tab");
        setSpecsTab("tab");
    }

    const openSpecs = () => {
        if (specsTab === "tab active-tab") {
            setActiveTab(false);
            setSpecsTab("tab");
        } else {
            setActiveTab(true);
            setSpecsTab("tab active-tab");
        }
        setReviewsTab("tab");
        setDescriptionTab("tab");
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = parseInt(event.target.value);
        if (!isNaN(newQuantity) && newQuantity > 0) {
            setQuantity(newQuantity);
        }
        // console.log(newQuantity);
    };

    const handleWareHouseChange = (event: SelectChangeEvent<string>) => {
        // const selectedIndex = parseInt(event.target.value, 10);
        // const selectedValue = event.target.value;

      
        // if (!isNaN(selectedIndex)) {
       
        //     const selectedStock = stock[selectedIndex];
        //     localStorage.setItem('location', JSON.stringify(selectedStock.locationObj));
        //     dispatch(updateSelectedLocation(selectedStock.locationObj));
        //     dispatch(updateUserSystemId(selectedStock.locationObj.guestCutomerId));
        //     setLocationQuantity(selectedIndex.toString()); // Ensure this is a string to match the expected type

   
        // }
    };

    const handleAddServices = () => {
        const cart: CartItem[] = JSON.parse(localStorage.getItem("cart") ?? "[]");
        if ((user === "" || user === "guest" || user.showAdditionnalServices === true) && process.env.REACT_APP_WHOLESALE_FLAG === 'false') {
            setOpen(true);
            setError(false);
        } else {
            // console.log(" else ");
            if ((cart.length === 0) || ((cart.length > 0) && (selectedLocationStore) && (selectedLocationStore?.code === cart[0].locationCode))) {

              
                const newItem: CartItem = {
                    locationCode: selectedLocationStore?.code,
                    id: item?.SystemId ?? "",
                    no: item?.No ?? "",
                    showMessage :item[selectedLocationStore.locationCaption.charAt(0).toUpperCase() + selectedLocationStore.locationCaption.slice(1) + "Qty"]==0,          
                    name: item?.Model ?? "Product Name",
                    quantity: quantity ?? 0,
                    image: (item?.Image_Source && item?.Image_Source?.length > 0) ? item?.Image_Source : "../../assets/images/product/blog-1.jpg",
                    description: item?.Description ?? "",
                    price: item?.UnitPrice ?? 0,
                    services: [],
                    salesTax: item?.salesTax ?? "",
                    additionalTax1: item?.additionalTax1 ?? "",
                    additionalTax1Caption: item?.additionalTax1Caption ?? "",
                    additionalTax2: item?.additionalTax2 ?? "",
                    additionalTax2Caption: item?.additionalTax2Caption ?? ""
                };
                // console.log(" 1 ", item, newItem);
                dispatch(addToCart(newItem));
                setOpen(false);
                navigate('/cart')
            } else {
                setShowConfirmationModal(true);
            }
        }


        // if(quantity <= stock[parseInt(locationQuantity, 10)]?.qty){
        // }
        // else{
        //     quantity === 0? setErrorMessage("Please Select Number Of Tires Needed") : setErrorMessage("Not Enough In Stock");
        //     setError(true);
        // }
    }

    const buttonState = (item: Product, location?: Location): string => {
        const cart: CartItem[] = JSON.parse(localStorage.getItem("cart") ?? "[]");
        // if(stock.every((onestock:Stock)=>onestock.qty === 0)){
        //     return "OUT OF STOCK";
        // }
        // else
       
        if ((cart.length === 0) || ((cart.length > 0) && (selectedLocationStore) && (selectedLocationStore?.code === cart[0].locationCode))) {
            if ((cart.length > 0) && (cart.find(item1 => item1.id === item.SystemId))) {
                return "ADDED TO CART";
            }
        }
        return "ADD TO CART";
    };

    const styles = {
        leftTriangle: {
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '0px 20px 14px 0px',
            borderColor: 'transparent rgb(28.5, 99, 30) transparent transparent',
            zIndex: 2
        },
        rightTriangle: {
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '0px 0 14px 20px',
            borderColor: 'transparent transparent transparent rgb(28.5, 99, 30)',
            zIndex: 2
        }
    };

    const parseHTML = (htmlString: string) => {
        return <div dangerouslySetInnerHTML={{__html: htmlString}}/>;
    };

    return <Grid container spacing={1} xs={12}

    ><Helmet>
        <meta property="og:title" content={item.Model}/>
        <meta property="og:description" content={`Check out the details of ${item.Model} at TireZone`}/>
        <meta property="og:image" content={item.Image_Source ?? "../../assets/images/no-image-icon-23485.png"}/>
        <meta property="og:url" content="http://tirezone.co"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={item.Model}/>
        <meta name="twitter:description" content={`Check out the details of ${item.Model} at TireZone`}/>
        <meta name="twitter:image" content={item.Image_Source ?? "../../assets/images/no-image-icon-23485.png"}/>
    </Helmet>
        {(item.modelOfferText && item.modelOfferText.length > 0) && <>
            <Grid item xs={12} sx={{
                height: "45px",
                backgroundColor: "rgb(38, 132, 40)",
                color: "rgb(255, 255, 255)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px !important"
            }}>

                {ReactHtmlParser(item.modelOfferText)}

            </Grid>
            <Grid item xs={12} sx={{
                height: "25px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: "0px !important"
            }}>
                <div style={styles.leftTriangle}></div>
                <div style={styles.rightTriangle}></div>
            </Grid>
        </>}
        <Grid item mt={2} xs={5} lg={2} style={{textAlign: 'center'}} px={{xs: 1, lg: 0}}>
            <img src={item.Image_Source ?? require("../../assets/images/no-image-icon-23485.png")}
                 alt={item.Model}
                 style={{width: "auto", height: "100px", cursor: "pointer", marginBottom: "10px"}}
                 onClick={handleImageClick}/>
            <Dialog open={imageModalOpen} onClose={() => setImageModalOpen(false)}>
                <DialogContent sx={{overflow: "hidden", width: "400px", height: "400px", position: "relative"}}>
                    <IconButton
                        style={{position: "absolute", top: 0, right: 0, zIndex: 1}}
                        onClick={() => setImageModalOpen(false)}
                    >
                        <ClearIcon/>
                    </IconButton>
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <img
                            src={item.Image_Source ?? require("../../assets/images/no-image-icon-23485.png")}
                            alt={item.Model}
                            style={{maxWidth: "100%", maxHeight: "100%"}}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </Grid>
        <Grid item xs={7} lg={2} pr={{md: 1}}>
            {item.brand_image_source ? <img src={item.brand_image_source} alt="Model's Logo"
                                            style={{width: "180px", height: "auto", marginBottom: "0px"}}/> :
                <h3>{item.Model ?? ""}</h3>}
            <Typography sx={{
                   
                    color: "black"
                }}>{item.Brand ?? "N/A"}</Typography>
            <Typography sx={{marginTop: "5px", color: "black"}}>{item.Model ?? "N/A"}</Typography>
            <Typography noWrap
                        sx={{marginTop: "5px", color: "black"}}>{`${item.TireSize} ${item.loadIndex} ${item.speedRating}`}</Typography>
        </Grid>
        <Grid item xs={12} lg={2} pr={{md: 1}}>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{
                    fontSize: "14px",
                    color: "black"
                }}>UTQG: <b>{item.Utqg ?? "N/A"}</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{
                    fontSize: "14px",
                    color: "black"
                }}>SideWall: <b>{item.SideWall ?? "N/A"}</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{
                    fontSize: "14px",
                    color: "black"
                }}>Warranty: <b>{item.Warranty ?? "N/A"}</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{
                    fontSize: "14px",
                    color: "black"
                }}>MFR Part
                    No: <b>{item.MfrPartNo ?? "N/A"}</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="body2" sx={{
                    fontSize: "14px",
                    color: "black"
                }}>OE: <b>{(item?.oEDescription && item?.oEDescription?.length > 0) ? item.oEDescription : "N/A"}</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" sx={{
                    fontSize: "14px",
                    color: "black"
                }}>Run
                    Flat: <b>{(item?.RunFlat) ? "Yes" : "N/A"}</b></Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} lg={2} px={{md: 1}}>
            <Box display="flex" alignItems="center" width="100%">
                <Typography fontWeight="bold" fontSize="24px" color="#E7131A">
                    {`$${item.UnitPrice.toFixed(2) ?? '-'}`}
                </Typography>
                <Typography fontWeight="bold" fontSize="18px" color="#E7131A"
                            sx={{ml: 0.5}}>
                    {((item.unitOfMeasureDescription) && (item.unitOfMeasureDescription.length > 0)) ? (item.unitOfMeasureDescription) : `EA`}
                </Typography>
                {/* WHOLESALE Price Description */}
                {isUser && item.alternatePrice !== "0" &&
                    <Typography fontWeight="bold" fontSize="16px" color="#E7131A"
                                sx={{ml: 0.5}}>
                        /qty {item.priceDescription}
                    </Typography>
                }
            </Box>

            {/* WHOLESALE Alternate Price  */}
            {isUser && item.alternatePrice !== "0" &&
                <Box display="flex" alignItems="center">
                    <Typography fontWeight="bold" fontSize="24px" color="#E7131A">
                        {`$${parseFloat(item.alternatePrice ?? "").toFixed(2) ?? ""}`}
                    </Typography>
                    <Typography fontWeight="bold" fontSize="18px" color="#E7131A"
                                sx={{ml: 0.5}}>
                        {((item.unitOfMeasureDescription) && (item.unitOfMeasureDescription.length > 0)) ? (item.unitOfMeasureDescription) : `EA`}
                    </Typography>
                    <Typography fontWeight="bold" fontSize="16px" color="#E7131A"
                                sx={{ml: 0.5}}>
                        /qty {item.alternatePriceDescription}
                    </Typography>
                </Box>
            }
            {/* Tax Display */}
            {item.additionalTax1 && item.additionalTax1 !== "0" &&
                <Box display="flex" alignItems="center">
                    <Typography fontWeight="bold" fontSize="16px" color="#E7131A">
                        {`+$${parseFloat(item.additionalTax1).toFixed(2) ?? ""}`}
                    </Typography>
                    <Typography fontWeight="bold" fontSize="12px" color="#E7131A"
                                sx={{ml: 0.5}}>
                        FET
                    </Typography>
                </Box>
            }
        </Grid>
       { stock && stock.length > 0  && <Grid item xs={12} lg={2} pr={{md: 1, lg: 1}}>


    

    
      
         <Typography sx={{
                fontSize: "14px",
                mb: stock[parseInt(locationQuantity, 10)]?.qty === 0 ? 1 : 2,
                minHeight: stock[parseInt(locationQuantity, 10)]?.qty === 0 ? 0 : 45,
                display: 'flex',
                color: "black",
            }}>
                 <LocationOnIcon sx={{fontSize: "20px"}}/>
                <span>
  {stock[parseInt(locationQuantity, 10)]?.locationObj.name} {" ("}
  <span
    style={{
      color: stock[parseInt(locationQuantity, 10)]?.qty === 0 ? "blue" : "inherit",
    }}
  >
    {stock[parseInt(locationQuantity, 10)]?.qty}
  </span>
  {") in stock"}
</span>
              
            </Typography>
            {stock[parseInt(locationQuantity, 10)]?.qty === 0  && <Typography sx={{
                fontSize: "10px",
                mb:1,
                display: 'flex',
                color: "black",
            }}>
                 <span  style={{color:"blue"}}>
                (Please allow 1-2 business days for Tire availability before pickup)
</span>
              
            </Typography>}
            <FormControl fullWidth
                         sx={{borderRadius: "5px", color: "black", marginBottom: "10px"}}>
                <InputLabel id="demo-simple-select-label">Locations</InputLabel>
                <Select
                    sx={{borderRadius: "5px", color: "black"}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={locationQuantity}
                    label="Warehouse"
                    displayEmpty
                    renderValue={(selected) => {
                        return <em>
                            {`Regional ( ${totalQuantity} )`}
                        </em>;
                    }}
                    placeholder="Select Warehouse"
                    onChange={handleWareHouseChange}
                >
                    <MenuItem selected value="" style={{cursor: "default"}}
                              disabled={stock[parseInt(locationQuantity, 10)]?.qty === 0}>
                        {stock[parseInt(locationQuantity, 10)]?.locationObj.name} {" ( "} {stock[parseInt(locationQuantity, 10)]?.qty} {" )"}
                    </MenuItem>

                    {stock.map((oneStock: Stock, index: number) => (
                        ((oneStock.locationObj.guestCutomerId !== stock[parseInt(locationQuantity, 10)]?.locationObj.guestCutomerId) ) &&  oneStock.locationObj.name!='' && 
                        <MenuItem key={index} disabled={oneStock.qty === 0}
                                  value={index.toString()} style={{cursor: "default"}}>
                            {oneStock.externalInventory ? 'External' : oneStock.locationObj.name} ( {oneStock.qty ?? 0} )
                        </MenuItem>
                    ))}





                {showExternal && <MenuItem key={5} disabled={item.externalinventory === 0}
                                  value={"5"} style={{cursor: "default"}}>
                            {'External' } ( {item.externalinventory?? 0} )
                        </MenuItem>}
                 
                </Select>
            </FormControl>
        </Grid>}
        <Grid item xs={12} lg={2} pr={{md: 1}}>
        <FormControl fullWidth sx={{
    borderRadius: "5px",
    color: "black",
    marginBottom: "5px",
    width: "100%"
}}>
    <Box sx={{ display: 'flex', alignItems: 'center', width: "100%" }}>
        <TextField
            id="demo-simple-select"
            label="Quantity"
            type="number"
            value={quantity}
            sx={{
                width: "50%"
            }}
            onChange={handleChange}
            InputProps={{ sx: { borderRadius: "5px", color: "black" } }}
            InputLabelProps={{ shrink: true }} // Optional: shrink label on focus
        />
       
    </Box>
</FormControl>


            <div style={{display: "flex", flexDirection: "column", minWidth: 150, height: "55px" }}>
                <button
                    disabled={(buttonState(item) === "ADDED TO CART") /**((buttonState(item) === "ADDED TO CART") || (buttonState(item) === "OUT OF STOCK")) */}
                    type="button"
                    className="theme-btn theme-btn-eight"
                    onClick={handleAddServices}
                    style={(buttonState(item) === "ADDED TO CART") ? {
                        height: "55px",
                        padding: '2px',
                        backgroundColor: "darkgray"
                    } : {height: "55px", padding: '2px', backgroundColor: "#E7131A"}}
                >
                    {buttonState(item)}
                </button>
            </div>
        </Grid>
        {error && <Alert setError={setError} severity="error" text={errorMessage}/>}
        {optionalServices?.length !== 0 &&
            <OptionalServicesModal product={newCartItem} updateCart={updateCart} calculateTotal={calculateTotal}
                                   open={open} setOpen={setOpen}
                                   fields={newCartItem?.services && newCartItem?.services.length !== 0 ? newCartItem.services : optionalServices}
                                   setOptions={setOptions} quantity={quantity} isCart={false}
                                   showConfirmationModal={showConfirmationModal}
                                   setShowConfirmationModal={setShowConfirmationModal}/>}
    </Grid>
}

export default TireDetailsPage;
