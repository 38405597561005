import { useEffect } from "react";
import LoginPage from "../../pages/LoginPage/LoginPage";
import { useNavigate } from "react-router-dom";

const AuthGuard = ({ children }:any) => {
  

  const user = JSON.parse(localStorage.getItem("user") ?? `""`);
  const LoggedIn = user && user !== "" && user && user !== "guest";  

  const navigate = useNavigate()
  useEffect(() => {  
    if(!LoggedIn) navigate('/login')
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return LoggedIn ? children : <LoginPage />;
};

export default AuthGuard;
