import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageTitle from "../../components/PageTitle";
import { Button } from "@mui/material";

const ConfirmOrderPage: React.FC = () => {
  return (
    <body>
      <Helmet>
        <title>Your Order Has Been Placed Successfully</title>
        <meta name="description" content="Confirmation page for successful order placement" />
        <meta name="keywords" content="order, success, confirmation" />
        <meta name="author" content="TireZones" />
        
      </Helmet>

      <div
        className="boxed_wrapper"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          paddingLeft: "0vw",
          paddingRight: "0vw",
          minHeight: "70vh",
        }}
      >
        <PageTitle title={"Your Order Has Been Placed Successfully"} />
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button variant="contained" color="primary">
            Go Back To Home Page
          </Button>
        </Link>
      </div>
    </body>
  );
};

export default ConfirmOrderPage;
