import React from "react";
import { Helmet } from "react-helmet";
import Banner from "../../components/HomeBanner/HomeBanner";
import { Stack } from "@mui/material";
interface Props {
  location?: string | null;
}
const LandingPage: React.FC<Props> = ({location}) => {

  return (
    <Stack alignItems="center" justifyContent="center">
      <Helmet>
        <title>Tires - Tire Zone</title>
        <meta name="robots" content="index, follow" />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        
        <meta name="keywords" content="Tire Zone, tires, wheels, automotive services" />
        <meta name="author" content="TireZones" />
        

        <meta name="description" content="Tire Zone is a tire &amp; wheel dealer with shops in Covina, Ontario, Glendora - California. Tire Zone has deals on tires, wheels and automotive services. Save at Tire Zone!" />
        
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Tires - Tire Zone" />
        <meta property="og:description" content="Tire Zone is a tire &amp; wheel dealer with shops in Covina, Ontario, Glendora - California. Tire Zone has deals on tires, wheels and automotive services. Save at Tire Zone!" />
        <meta property="og:url" content="https://tirezoneusa.com/" />
        <meta property="og:site_name" content="Tire Zone" />
        <meta property="article:publisher" content="https://www.facebook.com/pages/Tire-Zone/167358669946020" />
        <meta property="article:modified_time" content="2022-12-03T07:49:02+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@tirezoneusa" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="1 minute" />
      </Helmet>
      <Banner location = {location}/>
    </Stack>
  );
};

export default LandingPage;
