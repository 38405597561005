import { useState, useEffect } from 'react';
import { Coupons, CouponsApiResponse } from '../models/app';
import { getCoupons } from '../services/GetCoupons';

export const useGetCoupons = () => {
  const [coupons, setCoupons] = useState<Coupons[] | null>(null);
  const [couponsLoading, setLoading] = useState(true);
  const [couponsError, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const apiResponse:CouponsApiResponse  = await getCoupons();
        const values: Coupons[] = apiResponse.Data;
        setCoupons(values);
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { coupons, couponsLoading, couponsError };
};
