

import  { useState, useEffect } from 'react';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isTablet, setIsTablet] = useState(
      window.innerWidth < 1025 && window.innerWidth > 767
    );
    const [width] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
          setIsMobile(window.innerWidth < 768);
          setIsTablet(window.innerWidth < 1025 && window.innerWidth > 767);
        }
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
const web = !isMobile && !isTablet
  return { isMobile, isTablet, web ,width };
};

export default useIsMobile