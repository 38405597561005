import React from "react";
import { createTheme, ThemeProvider, Grid, Typography, Box, Container } from "@mui/material";
import lightThemeOptions from "../../theme/lightThemeOptions";

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { RootState } from "../../store/ducks";

const lightTheme = createTheme(lightThemeOptions);

const AboutUsPage: React.FC = () => {
  const selectedLocation = useSelector(
    (state: RootState) => state?.store?.selectedLocation
  );
  return (
    <ThemeProvider theme={lightTheme}>
      <Helmet>
      <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
        <meta charSet="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        <meta name="format-detection" content="telephone=no"/>
        
        <title>About us - Tire Zone</title>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="About us - Tire Zone"/>
        <meta property="og:url" content="https://tirezoneusa.com/aboutus/"/>
        <meta property="og:site_name" content="Tire Zone"/>
        <meta property="article:publisher" content="https://www.facebook.com/pages/Tire-Zone/167358669946020"/>
        <meta property="article:modified_time" content="2023-03-03T06:55:31+00:00"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@tirezoneusa"/>
        <meta name="twitter:label1" content="Est. reading time"/>
        <meta name="twitter:data1" content="1 minute"/>
      </Helmet>
      <Box className="boxed_wrapper" sx={{ minHeight: '100vh', paddingTop: "10px" }}>
        {/* <PageTitle title={"About Us"} /> */}
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h1" gutterBottom component="div" sx={{ color: "#E7131A", fontSize: { xs: '24px', md: '40px' }, fontWeight: 'bold', mb: 2 }}>
                Who We Are
              </Typography>
              <Typography variant="body1" paragraph>
                Tire Zone is a family owned business that strives to provide the lowest prices and the best customer service in your area. The founder, Mike Nalbandian first opened our doors in Covina, California in 2007. Mike’s business plan in 2007 was to provide “Wholesale pricing to the public.” Today, that motto still lives as it is embedded in the heart and soul of Tire Zone. We guarantee you the best prices and service in town!
              </Typography>
              <Typography variant="body1" paragraph sx={{fontWeight: "bold", fontSize: {xs: '12px', md: '20px'}}}>
                Our tire service centers:
              </Typography>
              <Typography variant="body1" paragraph>
                Covina
                15940 e San Bernardino Rd #A
                Covina, CA 91722
              </Typography>
              <Typography variant="body1" paragraph>
                Glendora
                321 w Arrow Hwy
                Glendora, CA 91740
              </Typography>
              <Typography variant="body1" paragraph>
                Ontario
                940 w holt Blvd.
                Ontario, CA 91762
              </Typography>
              <Typography variant="body1" paragraph>
                Whether or not you reside in any of these three locations, feel free to contact us for an estimate. Or you can drop into any of our locations Monday-Saturday from 8:30am to 5pm.
              </Typography>
              <Typography variant="body1" paragraph>
                We can provide you with any size or brand tire including Goodyear, Michelin, Hankook, Kumho and more. Here are the addition services Tire Zone provides: Free flat repairs, Free Alignment checks and TPMS sensors (tire pressure monitoring system)
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ border: '1px solid darkgray', overflow: 'hidden' }}>
                <img src={selectedLocation?.aboutUsImage?? require("../../assets/images/product/blog-4.jpg")} style={{ width: '100%', height: 'auto' }} alt="About Us" />
              </Box>
            </Grid>
          </Grid>
        </Container>
        
      </Box>
    </ThemeProvider>
  );
};

export default AboutUsPage;
