import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { createTheme } from '@mui/system';
import lightThemeOptions from '../../theme/lightThemeOptions';

const theme = createTheme(lightThemeOptions)
const EmptyCart = () => {
  
  

  return (
    <div className="empty-cart-container" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <div style={{display:'flex',flexDirection:'row'}}>

      <h2>Your cart is empty</h2>
      <motion.div
        animate={{y:[0,20,0]}}
        transition={{ repeat: Infinity, duration: 2 }}
        >
        {/* Your cart icon here */}
        <i className="icon-shopping-cart" style={{fontSize:32, marginLeft:'1rem'}} />
      </motion.div>
        </div>
      <Link to="/">
        <motion.button
          style={{color:'var(--theme-color)'}}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          >
          Shop now
        </motion.button>
      </Link>
    </div>
  );
};

export default EmptyCart;
