import { useState, useEffect } from 'react';
import { GetBrands } from '../services/GetBrands';
import { Brand } from '../models/app';

export const useGetBrands = () =>
{
  const [brands, setBrands] = useState<any[] | null>(null);
  const [brandsLoading, setLoading] = useState(true);
  const [brandsError, setError] = useState<string | null>(null);

  useEffect(() =>
  {

    const fetchData = async () =>
    {
      try
      {
        setLoading(true);
        const apiResponse:any  = await GetBrands();
        const brandsList:Brand[] = apiResponse;
        console.log("brandsList", brandsList);
        setBrands(brandsList);
      } catch (error)
      {
        setError('Error fetching data');
      } finally
      {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return { brands, brandsLoading, brandsError };
};    