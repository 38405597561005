import { Fragment } from "react";
import Logo from "../UI/logo/Logo";
import { RootState } from "../../store/ducks";
import { useSelector } from "react-redux";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import ReactHtmlParser from "react-html-parser";
function Footer() {
  const locationsStore = useSelector(
    (state: RootState) => state?.store?.locations
  );
  
  // const [locations, setLocations] = useState<Location[]>(locationsStore);
  // const locations = useLocation();
  // const mapStyles = {
  //   height: "150px",
  //   width: "250px",
  // };

  // const defaultCenter = {
  //   lat: parseFloat(locations[0]?.latitude),
  //   lng: parseFloat(locations[0]?.longitude)
  // };
  return (
    <footer className="main-footer-one">
      {/* <div className="upper-box">
        <div className="auto-container">
            <div className="outer-box">
                <p>FREE Delivery over $49. Fulfilled by Bosch. </p>                          
                <p>Super discount for your first purchase </p>                          
            </div>
        </div>
    </div> */}
      <div className="lower-box">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-2 col-md-4">
              <div className="footer-widget footer-logo-widget">
                <div className="footer-logo">
                  <Logo />
                  

                </div>
                <p>Tire Zone is your one stop for tires and wheel alignments.</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 offset-lg-1">
              <div className="footer-widget footer-links-widget">
                <h4 className="footer-widget-title">Links</h4>
                <ul className="lsit">
                  <li>
                    <a href="/">Home</a>
                  </li>

                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                  <li>
                    <a href="/aboutus">About Us</a>
                  </li>
                  <li>
                    <a href="/coupons">Coupons</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
                    <div className="footer-widget footer-services-links-widget">
                        <h4 className="footer-widget-title">Why buy from us</h4>
                        <ul className="lsit">
                            <li><a href="#">Business Planning </a></li>
                            <li><a href="#">Human Resources</a></li>
                            <li><a href="#">Bonds & Com modities</a></li>
                            <li><a href="#">Financial Projections</a></li>
                        </ul>
                    </div>
                </div> */}
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-contact-widget">
                <h4 className="footer-widget-title">Get In Touch</h4>
                <ul>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                  <li>
                    <a href={`mailto:TireZoneUSA@hotmail.com`}>
                      Email TireZoneUSA@hotmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="footer-widget footer-contact-widget"
              style={{ marginTop: "3rem", marginLeft: "1rem" }}
            >
              {/* <LoadScript
                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API!}
                          > */}
              <div className="footer-locations-div">
                {locationsStore?.map((location: any, index: number) => (
                  <Fragment key={index}>
                    <ul>
                      <li>
                        <a href="#0" className="footer-contact-info">
                          {location?.displayName}
                        </a>
                      </li>
                      <li>
                        <a href={`tel:${location.phoneNo}`}>
                          {location.phoneNo}
                        </a>
                      </li>
                      <li>
                        <a href="#0">{location.address}</a>
                        <ul
                          className="social-icon"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          <li>
                            <a href={location?.facebookURL}>
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>

                          <li>
                            <a href={location?.instagramURL??"https://www.instagram.com/tirezoneusa?igsh=MzY1NDJmNzMyNQ=="}>
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <div style={{ marginBottom: "20px" }}>
                        {/* <div dangerouslySetInnerHTML={{ __html:location?.embed }}/>  */}
                        <div>
                          {ReactHtmlParser(location?.embed)}
                        </div>
                            {/* <GoogleMap
                              mapContainerStyle={mapStyles}
                              zoom={15}
                              center={{
                                lat: parseFloat(location.latitude),
                                lng: parseFloat(location.longitude),
                              }}
                              onClick={(event) => {
                                // const lat = event?.latLng?.lat();
                                // const lng = event?.latLng?.lng();
                                // const url = `https://www.google.com/maps/@${lat},${lng},${15}z`; // Adjust zoom level if needed
                                const url = `https://www.google.com/maps/search/?api=1&query=${
                                  "Tirezone " +
                                  location?.displayName +
                                  " " +
                                  location.address
                                }`;
                                window.open(url, "_blank");
                              }}
                            >
                              <Marker
                                position={{
                                  lat: parseFloat(location.latitude),
                                  lng: parseFloat(location.longitude),
                                }}
                                title={"Tirezone " + location?.displayName}
                              />
                            </GoogleMap> */}

                        </div>
                      </li>
                    </ul>
                  </Fragment>
                ))}

              </div>
                                          {/* </LoadScript> */}
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom-one">
        <div className="auto-container">
          <div className="row m-0 align-items-center justify-content-between">
            <div className="copyright-text">
              Copyright © 2024 <span>Tire Zone</span>. All rights reserved.
            </div>
            {/* <div className="copyright-card">
                    <h6>We accept :</h6>
                    <a href="#"><img src={require("../../assets/images/icons/card-1.png")} alt="card"/></a>
                    <a href="#"><img src={require("../../assets/images/icons/card-2.png")} alt="card"/></a>
                    <a href="#"><img src={require("../../assets/images/icons/card-3.png")} alt="card"/></a>
                    <a href="#"><img src={require("../../assets/images/icons/card-4.png")} alt="card"/></a>  
                </div> */}
            <ul className="menu">
              <li>
                <a href="/aboutUs">About Us</a>
              </li>
              {/* <li><a href="#">FAQ’s</a></li>
                    <li><a href="#">Privacy Policy </a></li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
