import React, { useEffect } from "react";
import { useGetInvoiceDetails } from "../../hooks/useGetInvoiceDetails";
import Loader from "../../components/UI/loader/Loader";
import { useParams, useSearchParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";

export default function UserInvoicesDetailsPage() {

  const { invoiceId } = useParams<string>();

  const user = JSON.parse(localStorage.getItem("user") ?? `""`);

  const { getInvoiceDetails, res, loading, error, invoiceDetails } =
    useGetInvoiceDetails();

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      await getInvoiceDetails(user.id, invoiceId ?? "");
    };

    fetchInvoiceDetails();
  }, []);
  
  return (
    <>
      {loading && <Loader />}
      <div className="boxed_wrapper" style={{paddingTop: "10px"}}>
        {/* <PageTitle title={"Order " + invoiceDetails && invoiceDetails?.OrderNumber} /> */}
        <section className="cart-section p_relative  pb_150">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 table-column">
                <div className="table-outer">
                  <table className="cart-table">
                    <thead className="cart-header">
                      <tr>
                        <th>&nbsp;</th>
                        <th className="prod-column">Product</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th className="price">Price</th>
                        <th className="quantity">Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetails &&
                        invoiceDetails.SalesInvoiceLines.map(
                          (invoice: any, index: number) => (
                            invoice.AttachedtoSequenceNo == 0?
                              (
                              <tr style={{borderBottom: "0px", borderTop:"1px solid #e6e6e6"}}>
                              <td colSpan={4} className="prod-column">
                                  <div className="prod-title" style={{color: "#E7131A"}}>
                                    {invoice.Description} <br />
                                  </div>
                                </td>
                                <td className="price" style={{color: "#E7131A"}}>${invoice.UnitPrice}</td>
                                <td className="qty" style={{color: "#E7131A", paddingLeft:"15px"}}>
                                    {invoice.Quantity}
                                  
                                </td>
  
                                <td className="sub-total" style={{color: "#E7131A"}}>
                                ${invoice.NetAmountIncludingTax}
                                </td>
                                </tr>
                                ) : 
                                (
                                  <tr style={{borderBottom: "0px", padding: "0px"}}>
                                  <td colSpan={4} className="prod-column" style={{padding: "0px"}}>
                                      <div className="prod-title"  style={{color: "#626b74"}}>
                                        {invoice.Description} <br />
                                      </div>
                                    </td>
                                    <td className="price" style={{color: "#626b74", padding: "0px"}}>${invoice.UnitPrice}</td>
                                    <td className="qty" style={{color: "#626b74", padding: "0px", paddingLeft:"15px"}}>
                                        {invoice.Quantity}
                                      
                                    </td>
      
                                    <td className="sub-total" style={{color: "#626b74", padding: "0px"}}>
                                    ${invoice.NetAmountIncludingTax}
                                    </td>
                                    </tr>
    
                                )
                          
                          )
                        )}
                              <tr style={{borderBottom: "0px", borderTop:"1px solid #e6e6e6"}}>
                              <td colSpan={4} className="prod-column">
                                  <div className="prod-title" style={{color: "#E7131A"}}>
                                    Total Amount (tax included) <br />
                                  </div>
                                </td>
                                <td className="price" style={{color: "#E7131A"}}></td>
                                <td className="qty" style={{color: "#E7131A"}}>
                                    
                                  
                                </td>
  
                                <td className="sub-total" style={{color: "#E7131A"}}>
                                ${invoiceDetails && invoiceDetails.TotalAmountIncludingTax}
                                </td>
                                </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
