import { useState, useEffect } from 'react';
import { FilterKeyMap, FilterKeys, Filters, Location, OneFilter, Product, SearchBody, SearchInput, SearchResultsApiResponse, Stock } from '../models/app';
import { getProducts } from '../services/GetProducts';
import { useSelector } from 'react-redux';
import { RootState } from '../store/ducks';

const filterKeyMap: FilterKeyMap = {
  brand: 'Brand',
  model: 'Model',
  speedrating: 'speedRating',
  loadindex: 'loadIndex',
  sidewalls: 'SideWall',
  season: 'Season',
  oeCode: 'OeCode',
};

export const useProducts = () => {
  const locationsStore = useSelector((state: RootState) => state?.store?.locations);
  const [locations, setLocations] = useState<Location[]>(locationsStore);

  useEffect(() => {
    setLocations(locationsStore);
  }, [locationsStore]);

  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[] | null>(
    null
  );

  const [sortCriteria, setSortCriteria] = useState("price");
  const [sortDirection, setSortDirection] = useState("asc");

  const [filters, setFilters] = useState<Filters>({
    loadindex: [],
    speedrating: [],
    ratio: [],
    wheelSize: [],
    width: [],
    sidewalls: [],
    brand: [],
    model: [],
    season: [],
    oeCode: [],
  });

  const [initialFilters, setInitialFilters] = useState<Filters>({
    loadindex:[], 
    speedrating:[],
    ratio:[],
    wheelSize:[],
    width:[],
    sidewalls:[], 
    brand:[], 
    model:[], 
    season:[],
    oeCode: [],
  });
  const [selectedFilters, setSelectedFilters] = useState<Record<string, OneFilter[] | null>>({});
  const [runFlat, setRunFlat] = useState(false);
  const [showExternal, setShowExternal] = useState(false);
  const [inStock, setInStock] = useState<boolean>(true);
  const [productsLoading, setLoading] = useState(true);
  const [productsError, setError] = useState<string | null>(null);

  const sortProducts = (products: Product[]) => {
    return products.sort((a, b) => {
      let compareValue = 0;
      switch (sortCriteria) {
        case "price":
          compareValue = a.UnitPrice - b.UnitPrice;
          break;
        case "brand":
          compareValue = (a.Brand || "").localeCompare(b.Brand || "");
          break;
        case "model":
          compareValue = (a.Model || "").localeCompare(b.Model || "");
          break;
        case "availability":
          const stockA: Stock[] = getLocationsQuantities(a);
          const stockB: Stock[] = getLocationsQuantities(b);
          const totalQuantityA = stockA.reduce(
            (acc, currentStock) => acc + currentStock.qty,
            0
          );
          const totalQuantityB = stockB.reduce(
            (acc, currentStock) => acc + currentStock.qty,
            0
          );

          compareValue = totalQuantityA - totalQuantityB;
          break;
        default:
          compareValue = 0;
      }
      // Reverse the comparison result if sort direction is descending
      return sortDirection === "desc" ? -compareValue : compareValue;
    });
  };

  useEffect(() => {
    
    setProducts(sortProducts([...products]));
    setFilteredProducts(sortProducts([...(filteredProducts ?? products)]));
    
  }, [sortCriteria, sortDirection]);

  useEffect(() => {
    applyFilters();
  }, [selectedFilters, runFlat, products]);

  useEffect(() => {
    // applyFilters();
  }, [inStock]);

  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  const fetchData = async (
    data: SearchInput,
    dataRear: SearchInput,
    UserSystemId: string,
    isCatalog: boolean,
    inStock: boolean,
  ) => {
    setLoading(true);
    const dataRear1 = {
      Width: "",
      Ratio: "",
      Wheel: "",
      Season: "",
      speedRating: "",
      loadIndex: "",
      RawSize: "",
    };
    if (areEqual(dataRear, dataRear1)) {
      var bodyFormatted: SearchBody[] = [];
      if (isCatalog) {
        bodyFormatted = [...formatSearchBodyCatalog(data, inStock)];
      } else {
        bodyFormatted = [
          ...formatSearchBody(data, inStock),
          ...formatSearchBody(dataRear, inStock),
        ];
      }

      try {
        // console.log("1", bodyFormatted);
        const apiResponse: SearchResultsApiResponse = await getProducts(
          bodyFormatted,
          UserSystemId,
          false
        );
        setProducts(
          await sortProducts(apiResponse?.Data?.data !== null ? apiResponse?.Data?.data : [])
        );
        setFilteredProducts(
          await sortProducts(apiResponse?.Data?.data !== null ? apiResponse?.Data?.data : [])
        );
        setFilters(apiResponse?.Data?.filters ?? []);
        setInitialFilters(apiResponse?.Data?.filters ?? []);
        // console.log("1", apiResponse?.Data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
      }
    } else {
      const bodyFormatted: SearchBody[] = [
        ...formatSearchBodyRear(data, dataRear, inStock),
      ];
      try {
        // console.log("2", bodyFormatted);
        setLoading(true);
        const apiResponse: SearchResultsApiResponse = await getProducts(
          bodyFormatted,
          UserSystemId,
          true
        );
        // console.log(apiResponse);
        setProducts(
          await sortProducts(apiResponse?.Data?.data !== null ? apiResponse?.Data?.data : [])
        );
        setFilteredProducts(
          await sortProducts(apiResponse?.Data?.data !== null ? apiResponse?.Data?.data : [])
        );
        setFilters(apiResponse?.Data?.filters ?? []);
        setInitialFilters(apiResponse?.Data?.filters ?? []);
        // console.log("2", apiResponse?.Data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
      }
    }
    // console.log("loading done");
    setLoading(false);
  };

  const formatSearchBody = (input: SearchInput, inStock: boolean): SearchBody[] => {
    if(inStock === true){
      return Object?.entries(input)
      ?.filter(([_, value]) => value.length !== 0)
      ?.map(([field, value]) => ({
        Field: field,
        Value: value,
        OnlyAvailableInStock: true,
      }));
    }
    else{
      return Object?.entries(input)
      ?.filter(([_, value]) => value.length !== 0)
      ?.map(([field, value]) => ({
        Field: field,
        Value: value,
        OnlyAvailableInStock: false,
      }));
    }
  };

  const formatSearchBodyRear = (
    input: SearchInput,
    inputRear: SearchInput,
    inStock: boolean
  ): SearchBody[] => {
    if(inStock === true){
      const body = Object?.entries(input)
        ?.filter(([_, value]) => value.length !== 0)
        ?.map(([field, value]) => ({
          Type: 1,
          Field: field,
          Value: value,
          OnlyAvailableInStock: true,
        }));

      const bodyRear = Object.entries(inputRear)
        ?.filter(([_, value]) => value.length !== 0)
        ?.map(([field, value]) => ({
          Type: 2,
          Field: field,
          Value: value,
          OnlyAvailableInStock: true,
        }));

        const bodyCombined = body?.concat(bodyRear);
        return bodyCombined;
    }
    else{
      const body = Object?.entries(input)
        ?.filter(([_, value]) => value.length !== 0)
        ?.map(([field, value]) => ({
          Type: 1,
          Field: field,
          Value: value,
          OnlyAvailableInStock: false,
      }));

      const bodyRear = Object.entries(inputRear)
        ?.filter(([_, value]) => value.length !== 0)
        ?.map(([field, value]) => ({
          Type: 2,
          Field: field,
          Value: value,
          OnlyAvailableInStock: false,
      }));

      const bodyCombined = body?.concat(bodyRear);
      return bodyCombined; 
    }
  };

  const formatSearchBodyCatalog = (input: SearchInput, inStock: boolean): SearchBody[] => {
    if(inStock === true){
      return Object?.entries(input)
      ?.filter(([_, value]) => value.length !== 0)
      ?.map(([field, value]) => ({
        Field: field,
        Value: value,
        OnlyAvailableInStock: true,  
      }));
    }
    else{
      return Object?.entries(input)
      ?.filter(([_, value]) => value.length !== 0)
      ?.map(([field, value]) => ({
        Field: field,
        Value: value,
        OnlyAvailableInStock: false,
      }));
    }

  };

  const applyFilters = () => {
    setLoading(true);
    // console.log("selectedFilters", selectedFilters);
  
    let newFilteredProducts = products.filter(product => {
      // Iterate over each selected filter
      return Object.entries(selectedFilters).every(([key, selectedValues]) => {
        // If no filters are selected for this key, pass all products
        if (!selectedValues || selectedValues.length === 0) return true;
  
        // Get the corresponding product attribute key from the filterKeyMap or use the key directly
        const productKey = filterKeyMap[key] || key;
  
        // For each selected filter value, check if the product attribute matches the filter's code
        // Adjust this logic if your product's attributes structure requires
        return selectedValues.some(filterValue => {
          // Assuming product attributes are stored directly under product[productKey] and are strings
          // Compare the product's attribute to the filter's code
          return product[productKey] === filterValue.code;
        });
      }) && (runFlat === undefined || ((runFlat === true)? (product?.RunFlat === true): true)); // Check for RunFlat if needed
    });
  
    // console.log("newFilteredProducts: ", newFilteredProducts);
    setFilteredProducts(newFilteredProducts);
    updateFilterCounts(newFilteredProducts);
    setLoading(false);

  };
  
  

  function isFilterKey(key: any): key is FilterKeys {
    const validKeys: FilterKeys[] = ["loadindex", "speedrating", "ratio", "wheelSize", "width", "sidewalls", "brand", "model", "season", "oeCode"];
    return validKeys.includes(key);
  }

  const updateFilterCounts = (filteredProducts: Product[]) => {
    const newFilters: Filters = JSON.parse(JSON.stringify(filters));
  
    Object.keys(newFilters).forEach(filterCategory => {
      // First, check if the filter category exists in selectedFilters and is not null
      const selectedFilterOptions = selectedFilters[filterCategory];
      if (selectedFilterOptions && selectedFilterOptions.length > 0) {
        // If there are selected filters in this category, skip updating counts
        return;
      }
  
      const updatedFilterOptions = newFilters[filterCategory].map(option => {
        const count = filteredProducts.reduce((acc, product) => {
          const productKey = filterKeyMap[filterCategory] || filterCategory;
          // Ensure product[productKey] is not null before comparison
          const productValue = product[productKey];
          if ((productValue !== null) && (productValue === option.code) && (runFlat === undefined || ((runFlat === true)? (product?.RunFlat === true): true))) {
            return acc + 1;
          }
          return acc;
        }, 0);
  
        return { ...option, count };
      });
  
      newFilters[filterCategory] = updatedFilterOptions;
    });
  
    setFilters(newFilters);
  };
  
  

  function areEqual(obj1: SearchInput, obj2: SearchInput): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1?.length !== keys2?.length) {
      return false;
    }
    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  const updateSelectedFilters = (filterType: string, values: OneFilter[] | null) => {
    setSelectedFilters(prev => ({ ...prev, [filterType]: values }));
  };

  const toggleRunFlat = () => {
    setRunFlat((prev) => !prev);
  };
  const toggleShowExternal = () => {
    setShowExternal((prev) => !prev);
  };


  const toggleInStock = () => {
    setInStock((prev) => !prev);
  };

  const getLocationsQuantities = (product: Product, location?: Location, showExternal?:boolean) => {
    var result: Stock[] = [];
   
    var external : Stock = {
      locationObj:   locations[0],
      locationNumber:  "External Inventory",
      qty: product.externalinventory ?? 0,
      externalInventory :true
    };



    var tempStock: Stock = {
      locationObj: locations[0],
      locationNumber: locations[0]?.locationCaption,
      qty: product.Location1Qty ?? 0,
      externalInventory :false
    };
    if (location) {
      var tempString = `Location${location.locationCaption.substring(
        location.locationCaption.length - 1
      )}Qty`;
      tempStock = {
        locationObj: location,
        locationNumber: location.locationCaption,
        qty: product[tempString] ?? 0,
        externalInventory :false
      };
      result = [...result, tempStock];
    } else {
      locations.map((location: Location) => {
        var tempString = `Location${location.locationCaption.substring(
          location.locationCaption.length - 1
        )}Qty`;
        tempStock = {
          locationObj: location,
          locationNumber: location.locationCaption,
          qty: product[tempString] ?? 0,
          externalInventory :false
        };
        result = [...result, tempStock];
      });
    }
  if(showExternal){

    result = [...result, external];
  }

  
    return result;
  };

  return {
    filterKeyMap,
    applyFilters,
    fetchData,
    getLocationsQuantities,
    filters,
    products,
    filteredProducts,
    initialFilters,
    updateSelectedFilters,
    toggleRunFlat,
    toggleShowExternal,
    toggleInStock,
    toggleSortDirection,
    sortCriteria,
    setSortCriteria,
    sortDirection,
    setSortDirection,
    productsLoading,
    productsError,
  };
};
