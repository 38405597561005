import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FilterKeyMap, FilterKeys, Filters, OneFilter } from "../../models/app";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface CatalogSideBarProps {
  filters: Filters;
  selectedFilters: any;
  setSelectedFilters: React.Dispatch<React.SetStateAction<any>>;
  runFlat: boolean;
  setRunFlat: React.Dispatch<React.SetStateAction<boolean>>;
  inStock: boolean;
  showExternal : boolean;
  setShowExternal: React.Dispatch<React.SetStateAction<boolean>>;
  setInStock: React.Dispatch<React.SetStateAction<boolean>>;
  applyFilters: () => void;
  updateSelectedFilters:any, 
  toggleRunFlat:any,
  toggleShowExternal:any,
  toggleInStock:any,
  initialFilters:Filters
  filterKeyMap: FilterKeyMap,
}

const CatalogSideBar: React.FC<CatalogSideBarProps> = ({
  filters,
  selectedFilters,
  setSelectedFilters,
  runFlat,
  showExternal,
  setRunFlat,
  setShowExternal,
  inStock,
  setInStock,
  applyFilters,
  updateSelectedFilters, 
  toggleRunFlat,
  toggleShowExternal,
  toggleInStock,
  initialFilters,
  filterKeyMap,
}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isFiltersVisible, setIsFiltersVisible] = useState(!isMobile);

  useEffect(() => {
    setIsFiltersVisible(!isMobile);
  }, [isMobile]);

  const toggleFiltersVisibility = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const handleFilterChange = (filterName: string, newValues: (OneFilter | null)[]) => {
    setSelectedFilters((prev:any) => ({ ...prev, [filterName]: newValues }));
    updateSelectedFilters(filterName, newValues);
    applyFilters();
  };

  function isFilterKey(key: any): key is FilterKeys {
    const validKeys: FilterKeys[] = [
      'loadindex',
      'speedrating',
      'ratio',
      'wheelSize',
      'width',
      'sidewalls',
      'brand',
      'model',
      'season',
      'oeCode',
    ];
    return validKeys.includes(key);
  }

  return (
    <div style={{ border: '2px solid #e5e5e5', borderRadius: '5px', padding: '10px', height: 'fit-content' }}>
      <Accordion disableGutters elevation={0} square expanded={isFiltersVisible} onChange={toggleFiltersVisibility} sx={{ width: '100%', borderRadius: '5px', '&::before': { display: 'none' } }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ '& .MuiAccordionSummary-content': { margin: '0px' } }}>
          <Typography variant="h6" sx={{ fontSize: '18px' }}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '10px', paddingBottom: '0px', marginBottom: '0px' }}>
        <FormGroup>
            <FormControlLabel
              control={<Switch checked={runFlat} onChange={() => { setRunFlat(!runFlat); toggleRunFlat(); applyFilters(); }} />}
              label="Run Flat"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={showExternal} onChange={() => { setShowExternal(!showExternal); toggleShowExternal(); applyFilters(); }} />}
              label="Include External Inventory"
            />
          </FormGroup>
          {Object.entries(initialFilters).filter(([filterName]) => (filterName !== 'width' && filterName !== 'wheelSize' && filterName !== 'ratio') && (isFilterKey(filterName))).map(([filterName, initialFilterOptions]) => (
            <div key={filterName} style={{ marginBottom: '10px', marginTop: '0px' }}>
              <Typography variant="h6" style={{ fontSize: '16px' }}>
                {filterKeyMap[filterName] ?? filterName}
              </Typography>
              <FormControl fullWidth margin="none">
                <Autocomplete
                  multiple
                  value={selectedFilters[filterName] || []}
                  onChange={(_, newValues) => handleFilterChange(filterName, newValues)}
                  options={initialFilterOptions.filter(option => {
                    const currentOption = filters[filterName].find(o => o.code === option.code);
                    return currentOption && currentOption.count > 0; 
                  })}                  
                  getOptionLabel={(option: OneFilter) => {
                    const currentOption = filters[filterName].find(o => o.code === option.code);
                    const count = currentOption ? currentOption.count : 0;
                    return `${option.description} (${count})`;
                  }}
                  renderInput={(params) => <TextField {...params} label={filterName} />}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                />
              </FormControl>
            </div>
          ))}
       
          {/* <FormGroup>
            <FormControlLabel
              control={<Switch checked={!inStock} onChange={() => { setInStock(!inStock); toggleInStock(); }} />}
              label="Include Out of Stock Items"
            />
          </FormGroup> */}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};


export default CatalogSideBar;