import { useState, useEffect } from 'react';
import { OptionalServices } from '../models/app';
import { getToken } from '../services/GetToken';
import { GetAdditionalServices } from '../services/GetAdditionalServices';

export const useGetAdditionalServices = (itemID: string, quantity: string, CustomerId: string) => {
  const [optionalServices, setOptionalServices] = useState<OptionalServices[]>([]);
  const [optionalServicesLoading, setLoading] = useState(true);
  const [optionalServicesError, setError] = useState<string | null>(null);

  useEffect(() => {
    // console.log(itemID);
    const fetchOptionalServices = async (itemID: string, quantity: string, CustomerId: string) => {
        try {
          setLoading(true);
          
          const apiResponse:any = await GetAdditionalServices( itemID, quantity, CustomerId);
          setOptionalServices(apiResponse?.Data);    
    
        } catch (error) {
            // console.log(error);
          setError('Error fetching data');
        } finally {
          setLoading(false);
        }
      };

    fetchOptionalServices( itemID, quantity, `6ebdaed5-39d5-ee11-904c-6045bdece3b0`);
    // console.log(optionalServices);
  },[]);



  return { optionalServices, optionalServicesLoading, optionalServicesError };
};



/**
 *           await setOptionalServices([
            {
                "OdataEtag": null,
                "AddServicesGroupCode": "TPK",
                "CalcType": "Fixed",
                "Selected": false,
                "ServiceItemId": "dbcb84b9-7aaa-ee11-be38-002248221f5f",
                "ServiceCode": "ALIGN",
                "ServiceDescription": "WHEEL ALIGNMENT",
                "Type": "Optional",
                "CustomerFilter": "{6EBDAED5-39D5-EE11-904C-6045BDECE3B0}",
                "QuantityFilter": "1",
                "ServicePrice": 70.0,
                "ServiceTax": 0.0,
                "ServicePriceWithTax": 70.0,
                "QtyOnOrder": 1
            },
            {
                "OdataEtag": null,
                "AddServicesGroupCode": "TPK",
                "CalcType": "Fixed",
                "Selected": false,
                "ServiceItemId": "919e1dbc-7baa-ee11-be38-002248221f5f",
                "ServiceCode": "ALIGN-X",
                "ServiceDescription": "WHEEL ALIGNMENT-X",
                "Type": "Optional",
                "CustomerFilter": "{6EBDAED5-39D5-EE11-904C-6045BDECE3B0}",
                "QuantityFilter": "1",
                "ServicePrice": 99.0,
                "ServiceTax": 0.0,
                "ServicePriceWithTax": 99.0,
                "QtyOnOrder": 1
            },
            {
                "OdataEtag": null,
                "AddServicesGroupCode": "TPK",
                "CalcType": "Fixed",
                "Selected": false,
                "ServiceItemId": "f343a1ff-7baa-ee11-be38-002248221f5f",
                "ServiceCode": "LIFEALIGN",
                "ServiceDescription": "LIFETIME WHEEL ALIGNMENT",
                "Type": "Optional",
                "CustomerFilter": "{6EBDAED5-39D5-EE11-904C-6045BDECE3B0}",
                "QuantityFilter": "1",
                "ServicePrice": 199.0,
                "ServiceTax": 0.0,
                "ServicePriceWithTax": 199.0,
                "QtyOnOrder": 1
            },
            {
                "OdataEtag": null,
                "AddServicesGroupCode": "TPK",
                "CalcType": "Fixed",
                "Selected": false,
                "ServiceItemId": "7d487146-7caa-ee11-be38-002248221f5f",
                "ServiceCode": "LIFEALIGN-X",
                "ServiceDescription": "LIFETIME WHEEL ALIGNMENT-X",
                "Type": "Optional",
                "CustomerFilter": "{6EBDAED5-39D5-EE11-904C-6045BDECE3B0}",
                "QuantityFilter": "1",
                "ServicePrice": 299.0,
                "ServiceTax": 0.0,
                "ServicePriceWithTax": 299.0,
                "QtyOnOrder": 1
            },
            {
                "OdataEtag": null,
                "AddServicesGroupCode": "TPK",
                "CalcType": "Variable",
                "Selected": true,
                "ServiceItemId": "d3cb2b5f-a3aa-ee11-be38-002248221f5f",
                "ServiceCode": "MB",
                "ServiceDescription": "MOUNT AND BALANCE",
                "Type": "Basic",
                "CustomerFilter": "{6EBDAED5-39D5-EE11-904C-6045BDECE3B0}",
                "QuantityFilter": "1",
                "ServicePrice": 20.0,
                "ServiceTax": 0.0,
                "ServicePriceWithTax": 20.0,
                "QtyOnOrder": 1
            },
            {
                "OdataEtag": null,
                "AddServicesGroupCode": "TPK",
                "CalcType": "Variable",
                "Selected": true,
                "ServiceItemId": "224abdb4-a1aa-ee11-be38-002248221f5f",
                "ServiceCode": "TD",
                "ServiceDescription": "TIRE DISPOSAL",
                "Type": "Basic",
                "CustomerFilter": "{6EBDAED5-39D5-EE11-904C-6045BDECE3B0}",
                "QuantityFilter": "1",
                "ServicePrice": 3.0,
                "ServiceTax": 0.0,
                "ServicePriceWithTax": 3.0,
                "QtyOnOrder": 1
            },
            {
                "OdataEtag": null,
                "AddServicesGroupCode": "TPK",
                "CalcType": "Percent",
                "Selected": false,
                "ServiceItemId": "1d3bc957-d0b5-ee11-a568-000d3a19daca",
                "ServiceCode": "TPP",
                "ServiceDescription": "TIRE PROTECTION PLAN",
                "Type": "Optional",
                "CustomerFilter": "{6EBDAED5-39D5-EE11-904C-6045BDECE3B0}",
                "QuantityFilter": "1",
                "ServicePrice": 13.09,
                "ServiceTax": 0.0,
                "ServicePriceWithTax": 13.09,
                "QtyOnOrder": 1
            },
            {
                "OdataEtag": null,
                "AddServicesGroupCode": "TPK",
                "CalcType": "Percent",
                "Selected": false,
                "ServiceItemId": "7f61b7b5-fbb6-ee11-a568-0022482952f8",
                "ServiceCode": "TPPX",
                "ServiceDescription": "TIRE PROTECTION PLAN EXOTIC",
                "Type": "Optional",
                "CustomerFilter": "{6EBDAED5-39D5-EE11-904C-6045BDECE3B0}",
                "QuantityFilter": "1",
                "ServicePrice": 15.4,
                "ServiceTax": 0.0,
                "ServicePriceWithTax": 15.4,
                "QtyOnOrder": 1
            },
            {
                "OdataEtag": null,
                "AddServicesGroupCode": "TPK",
                "CalcType": "Variable",
                "Selected": true,
                "ServiceItemId": "0ed8efcd-a2aa-ee11-be38-002248221f5f",
                "ServiceCode": "VSW",
                "ServiceDescription": "VALVE STEM & WEIGHTS (IF APPLICABLE)",
                "Type": "Basic",
                "CustomerFilter": "{6EBDAED5-39D5-EE11-904C-6045BDECE3B0}",
                "QuantityFilter": "1",
                "ServicePrice": 2.0,
                "ServiceTax": 0.205,
                "ServicePriceWithTax": 2.205,
                "QtyOnOrder": 1
            }
        ])
 */