import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
// import ReCAPTCHA from "react-google-recaptcha";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import AlertModal from "../../components/UI/alert/Alert";
import { useSearchParams } from "react-router-dom";
import { useChangePassword } from "../../hooks/useChangePassword";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, Paper } from "@mui/material";
import Loader from "../../components/UI/loader/Loader";
import { Helmet } from "react-helmet";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        TireZone
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ResetPasswordPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const resetToken = searchParams?.get("token")?.replaceAll(" ", "+");

  const { forgotPassword, res, loading, error } = useForgotPassword();
  const {
    changePassword,
    changePasswordres,
    changePasswordloading,
    changePassworderror,
  } = useChangePassword();
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [missingMail, setMissingMail] = React.useState(false);
  const [resetKey, setResetKey] = React.useState(Date.now());
  // const recaptchaRef = React.useRef(null);
  // const [recaptchaValue, setRecaptchaValue] = React.useState<any>(null);
  // const handleRecaptcha = (token: any) => {
  //   setRecaptchaValue(token);
  // };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.currentTarget.value);
    if (event.currentTarget.validity.valid) {
      setEmailError(false);
      setMissingMail(false);
    } else {
      setEmailError(true);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (resetToken) {
      const userData = {
        token: resetToken,
        password: data.get("password"),
      };
      await changePassword(userData);
    } else {
      if (!email) setMissingMail(true);
      else{ // if (recaptchaValue) 
        setMissingMail(false);
        const userData = {
          eMail: data.get("email"),
        };

        await forgotPassword(userData);
      }
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={{ height: "100%", borderRadius: "10px" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset Password</title>
        <meta name="description" content="Reset Password Page" />
        <meta name="keywords" content="Reset Password Page" />
        <meta name="author" content="TireZones" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        
      </Helmet>
      {loading && <Loader></Loader>}
      <CssBaseline />
      <Grid className="LoginSignUpScreen" item xs={false} sm={4} md={7}></Grid>

      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={0}
        square
        sx={{ backgroundColor: "#F9F9F9" }}
      >
        <Box
          sx={{
            my: 4,
            mx: 8,
            mt: 3,
            ml: 3,
            mr: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" color={"#E7131A"}>
            {resetToken
              ? "Enter New Password"
              : "Enter Email to Reset Password"}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 1, width: "100%" }}
          >
            {!resetToken && (
              <TextField
                margin="normal"
                autoFocus
                required
                fullWidth
                value={email}
                onChange={handleEmailChange}
                error={emailError || missingMail}
                helperText={
                  emailError
                    ? "Enter a valid email"
                    : missingMail
                    ? "Please Enter Mail"
                    : ""
                }
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                sx={{
                  // marginBottom: "3rem",
                  marginTop: "2rem",

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#2B313E",
                    },
                    "&:hover fieldset": {
                      borderColor: "#2B313E",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2B313E",
                  },
                }}
                InputProps={{ style: { borderRadius: "10px" } }}
                InputLabelProps={{
                  style: {
                    color: "#2B313E", // Label color
                  },
                }}
              />
            )}

            {resetToken && (
              <TextField
                required
                fullWidth
                name="password"
                label="New Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderRadius: "10px" },
                }}
                sx={{
                  // marginBottom: "3rem",
                  marginTop: "2rem",

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#2B313E",
                    },
                    "&:hover fieldset": {
                      borderColor: "#2B313E",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#2B313E",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "#2B313E", // Label color
                  },
                }}
              />
            )}

            {/* <ReCAPTCHA
              ref={recaptchaRef}
              key={resetKey}
              sitekey={
                process.env.REACT_APP_RECAPTCHA
                  ? process.env.REACT_APP_RECAPTCHA
                  : ""
              }
              onChange={(token: any) => handleRecaptcha(token)}
              style={{
                marginBottom: "0.5rem",
                marginTop: "2rem",
                width: "100%",
              }}
            /> */}
            {res && <AlertModal severity="success" text={res} />}
            {error && !res && (
              <Typography sx={{ color: "red", fontSize: "small", mt: 1 }}>
                {error}
              </Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#E7131A",
                borderRadius: "5px",
              }}
              className="theme-btn btn-style-one"
            >
              Reset
              <i className="flaticon-right-arrow"></i>
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2">
                  Login Here
                </Link>
              </Grid>
              <br></br>
              <Grid item>
                <Link href="/signup" variant="body2">
                  Register Here
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      {changePasswordres && (
        <AlertModal severitt="success" text={changePasswordres} />
      )}
    </Grid>
  );
}
