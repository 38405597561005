// apiService.ts
// import { AxiosRequestConfig } from 'axios';
// import axios from '../helpers/axiosInterceptor'
import axios from 'axios'

import { Endpoints } from '../constants/Endpoints';

export const getLocations = async () => {
  
  try {
    // const config: AxiosRequestConfig = {
    //   headers: {
    //     withCredentials: true,
    //   },
    // };
    const response:any = await axios.get(Endpoints.GetLocations);
    
        // Check for unauthorized error
        // if (response.data.Success === 0 && response.data.ErrorCode === 1) {
        //   // Handle unauthorized error, for example, redirect to login page
        //   console.error('Unauthorized access. Redirecting to login page.');
        //   // You can redirect the user to the login page or show a login modal
        //   // For example, uncomment the line below to redirect to the login page
        //   // window.location.href = '/login';
        //   throw new Error('Unauthorized access');
        // }
      
      return response.data;
    
    
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
