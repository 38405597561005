import  { AxiosRequestConfig } from 'axios';
import axios from '../helpers/axiosInterceptor'
import { Endpoints } from '../constants/Endpoints';

export const GetUserSalesOrderDetails = async (orderId: string) => {  //CustomerId:string, 
  
  try {
    
    const config: AxiosRequestConfig = {
      headers: {
        
        // 'CustomerId': CustomerId??``,
        'Content-Type': 'application/json',
      },
      params: {
        orderId: orderId,
      }
    };
    const response:any = await axios.get(Endpoints.GetSalesOrderDetails, config);
        if (response.data.Success === 0 && response.data.ErrorCode === 1) {
          console.error('Un expected error.');
          throw new Error('Un expected error');
        }
      return response.data;
    
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
