import { useState } from "react";

const useWheelOptions = (setErrorAlert: any) => {
  const [wheels, setWheels] = useState<any[]>([]);
  const [wheelsRear, setWheelsRear] = useState<any[]>([]);
  const [wheelsLoading, setLoading] = useState(false);
  const [wheelsError, setWheelsError] = useState<any>(null);
  const [wheelsErrorRear, setWheelsErrorRear] = useState<any>(null);
  
  const fetchWheelsData = async (data:any , profile: any, type: string) => {
    try {
      setLoading(true);
      // const data = await GetWheelsOptions(profile);
      
        const results = data.Data.wheelOptionsByRatio[profile].sort((a:any,b:any)=>a-b);
        if(results.length === 0){
          type === "Front"?setWheelsError("No Data for this profile size"):setWheelsErrorRear("No Data for this profile size");
        }
        else{
          type === "Front"?setWheelsError(null):setWheelsErrorRear(null);
          if(setErrorAlert){
            setErrorAlert(true);
          }
        }
        
        type === "Front"? setWheels(results): setWheelsRear(results);
      

      setLoading(false);
    } catch (error: any) {
      setWheelsError(error);
    }
  };

  return {
    wheels,
    wheelsRear,
    wheelsLoading,
    wheelsError,
    wheelsErrorRear,
    fetchWheelsData,
    setWheelsError,
    setWheelsErrorRear,
  };
};

export default useWheelOptions;
